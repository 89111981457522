<script setup lang="ts">
type SurveyBlockData = App.Surveys.Data.SurveyBlockData;

import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import SurveyOverviewAnswerCard from '@/components/surveys/surveys/SurveyOverviewAnswerCard.vue';

import AlignLeft from '@/icons/line/align-left.svg';
import Calendar from '@/icons/line/calendar.svg';
import CheckCircle from '@/icons/line/check-circle.svg';
import CheckSquare from '@/icons/line/check-square.svg';
import Envelope from '@/icons/line/envelope.svg';
import Paragraph from '@/icons/line/paragraph.svg';
import Phone from '@/icons/line/phone.svg';
import SliderH from '@/icons/line/slider-h.svg';
import Text from '@/icons/line/text.svg';
import ToggleOn from '@/icons/line/toggle-on.svg';

defineProps<{
  block: SurveyBlockData;
}>();

function getBlockTypeIcon(value) {
  return {
    select: CheckCircle,
    multiselect: CheckSquare,
    text: Text,
    textarea: AlignLeft,
    instruction: Paragraph,
    range: SliderH,
    boolean: ToggleOn,
    date: Calendar,
    email: Envelope,
    phone: Phone
  }[value];
}
</script>

<template>
  <div
    :key="block.id"
    class="divide-y-slate-200 divide-y overflow-hidden rounded-lg border border-slate-200 bg-white"
  >
    <div class="p-4">
      <div class="flex items-center">
        <div class="flex grow items-center gap-x-2">
          <div class="size-5 rounded bg-slate-100 p-[0.1875rem]">
            <component :is="getBlockTypeIcon(block.block_type.value)" />
          </div>
          <h3 class="text-sm font-medium leading-5 text-slate-900">
            {{ block.block_text }}
          </h3>
        </div>
        <span class="text-xs font-semibold text-slate-500"
          >{{ block.answers?.length ?? '0' }} responses</span
        >
      </div>
    </div>
    <div class="max-h-[400px] overflow-auto bg-slate-50 p-4">
      <div v-if="block.answers?.length" class="flex w-full flex-col gap-y-2">
        <SurveyOverviewAnswerCard
          v-for="answer in block.answers"
          :answer="answer"
          :block="block"
          :key="answer.id"
        />
      </div>
      <EmptyCard
        v-else
        isDashed
        isRounded
        title="No responses"
        description="Responses for this question will appear here"
      />
    </div>
  </div>
</template>
