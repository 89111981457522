<script setup lang="ts">
type StudentData = App.Students.Data.StudentData;
type StudentParentData = App.Students.Data.StudentParentData;

import CenteredContent from '@/components/content/CenteredContent.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';

import Envelope from '@/icons/line/envelope.svg';
import Phone from '@/icons/line/phone.svg';

const props = defineProps<{
  parent: StudentParentData;
  student: StudentData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.update.parents-guardians', { student: props.student.id }),
  updateInitials: true,
  fields: {
    first_name: props.parent?.first_name ?? null,
    last_name: props.parent?.last_name ?? null,
    email: props.parent?.email ?? null,
    cell_phone: props.parent?.cell_phone ?? null
  }
});

function handleFormSubmit() {
  !!props.parent
    ? form.submit()
    : form.submitWith({
        method: 'POST',
        url: route('students.store.parents-guardians', { student: props.student.id })
      });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :form="form"
      :isDirty="form.isDirty"
      :isProcessing="form.processing"
      :returnUrl
      :studentId="student.id"
      @onSubmit="handleFormSubmit"
    >
      <Fieldset title="Parent/Guardian">
        <FormInlineFields>
          <FormTextInput
            :error="form.errors.first_name"
            :modelValue="form.fields.first_name"
            label="First name"
            name="first_name"
            @update:modelValue="(value) => (form.fields.first_name = value as string)"
          />
          <FormTextInput
            :error="form.errors.last_name"
            :modelValue="form.fields.last_name"
            label="Last name"
            name="last_name"
            @update:modelValue="(value) => (form.fields.last_name = value as string)"
          />
        </FormInlineFields>
        <FormInlineFields>
          <FormTextInput
            :error="form.errors.email"
            :iconLeft="Envelope"
            :modelValue="form.fields.email"
            label="Email"
            name="email"
            @update:modelValue="(value) => (form.fields.email = value as string)"
          />
          <FormTextInput
            :error="form.errors.cell_phone"
            :iconLeft="Phone"
            :modelValue="form.fields.cell_phone"
            label="Phone"
            name="cell_phone"
            @update:modelValue="(value) => (form.fields.cell_phone = value as string)"
          />
        </FormInlineFields>
      </Fieldset>
    </StudentFormWrapper>
  </CenteredContent>
</template>
