<script setup lang="ts">
import { useToggle } from '@vueuse/core';
import Check from '@/icons/line/check.svg';
import Exclamation from '@/icons/line/exclamation.svg';
import Spinner from '@/icons/line/spinner.svg';
import Calendar from '@/icons/line/calendar.svg';
import DateTime, { DateTimeFormat } from '@/components/dateTime/DateTime.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

const props = withDefaults(
  defineProps<{
    message: App.Sms.Data.SmsData;
    showDateTime: boolean;
    dateTimeFormat?: DateTimeFormat;
  }>(),
  {
    dateTimeFormat: 'time'
  }
);

const [toggleableTimestamp, toggleShowTimestamp] = useToggle(!!props.showDateTime);

const messageStatus = computed(() => {
  switch (props.message.status.value) {
    case 'accepted':
    case 'delivered':
    case 'received':
    case 'sent':
      return 'sent';
    case 'queued':
      return 'pending';
    default:
      return props.message.status.value;
  }
});

const messageStatusComponent = computed(() => {
  switch (messageStatus.value) {
    case 'sent':
      return Check;
    case 'pending':
      return Spinner;
    case 'scheduled':
      return Calendar;
    default:
      return Exclamation;
  }
});
</script>

<template>
  <Tooltip
    :isDisabled="message.status.value === 'delivered'"
    side="top"
    :sideOffset="0"
    showArrow
    asChild
    variant="soft"
  >
    <span class="text-black">{{
      message.status.value === 'failed' ? message.failure_reason : `Message ${message.status.value}`
    }}</span>
    <template #trigger>
      <div
        :class="[
          'message cursor-pointer rounded-xl px-3 pt-3 text-sm font-medium leading-5',
          {
            'ml-auto text-white': message.direction.value === 'outbound',
            'bg-red-600': message.direction.value === 'outbound' && messageStatus === 'failed',
            'bg-secondary-900':
              message.direction.value === 'outbound' && messageStatus !== 'failed',
            'mr-auto bg-slate-100 text-slate-900': message.direction.value !== 'outbound',
            'pb-3': !toggleableTimestamp,
            'pb-1.5': toggleableTimestamp
          }
        ]"
        @click="toggleShowTimestamp()"
      >
        <div>
          <span v-html="message.body" class="whitespace-pre-wrap" />
        </div>
        <div v-if="toggleableTimestamp" class="flex items-center justify-end">
          <DateTime
            :class="[
              'text-xs uppercase',
              'text-slate-300' && message.direction.value === 'outbound',
              'text-slate-600' && message.direction.value !== 'outbound'
            ]"
            :date="message.sent_at"
            :format="dateTimeFormat"
          />
          <component
            :is="messageStatusComponent"
            :class="['ml-1 inline-flex w-3.5', { 'animate-spin': messageStatus === 'pending' }]"
          />
        </div>
      </div>
    </template>
  </Tooltip>
</template>

<style scoped>
.message {
  max-width: calc(100% - 40px);
}
</style>
