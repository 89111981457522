<script setup lang="ts">
import { differenceInMinutes, parseJSON } from 'date-fns';
import { sortBy } from 'lodash';
import { onEvent } from '@/hooks/useWebsockets';
import Message from '@/components/sms/Message.vue';
import ThreadsEmptyState from '@/components/emptyState/ThreadsEmptyState.vue';
import { indexThreadMessages } from '@/api/ThreadApi';
import { useMessageList } from '@/hooks/useMessageList';
import ComposeMessageInput from './ComposeMessageInput.vue';
import RecipientErrors from '@/domains/sms/components/RecipientErrors.vue';
import { WithLoadedRelations } from '@/@types/global';
import SmsData = App.Sms.Data.SmsData;

const props = withDefaults(
  defineProps<{
    thread: WithLoadedRelations<App.Sms.Data.ThreadData, 'recipient'>;
    lastUpdatedAt?: string;
    highlightMessageIds?: number[];
    scrollToLatestMessage?: boolean;
    messageContainer: HTMLElement | null;
    hasSmsEnabled?: boolean;
  }>(),
  {
    lastUpdatedAt: undefined,
    highlightMessageIds: () => [],
    hasSmsEnabled: false
  }
);

const { messages, groupedMessages, pushMessage, updateMessage, refreshAllPages, isLoading } =
  useMessageList<SmsData>({
    threadId: props.thread.id,
    lastUpdatedAt: props.lastUpdatedAt,
    api: indexThreadMessages,
    messageContainer: props.messageContainer,
    infiniteScrollDirection: 'top',
    sortKey: ['sent_at', 'id']
  });

onEvent(
  `thread.${props.thread.id}`,
  'message-received',
  ({ sms }: { sms: App.Sms.Data.SmsData }) => {
    pushMessage(sms);
  }
);

onEvent(
  `thread.${props.thread.id}`,
  'message-status-updated',
  ({ sms }: { sms: App.Sms.Data.SmsData }) => {
    updateMessage(sms);
  }
);

const form = useForm({
  fields: {
    body: '',
    scheduled_send_date: null,
    attachment_ids: [],
    recipients: [
      {
        recipient_id: props.thread.recipient?.id,
        recipient_type: props.thread.is_student_recipient ? 'student' : 'student_parent',
        phone_number: props.thread.phone_number ?? null
      }
    ],
    recipient_type: props.thread.is_student_recipient ? 'students' : 'parents'
  },
  method: 'POST',
  url: route('sms.outbound.store'),
  only: ['thread'],
  transform(fields) {
    return {
      ...fields,
      attachment_ids: fields.attachment_ids.map(({ id }) => id)
    };
  },
  hooks: {
    success() {
      refreshAllPages();
    }
  }
});

const MIN_INTERVAL_BETWEEN_MESSAGES_MINUTES = 5;

const mostRecentSentMessageId = computed(() => sortBy(messages.value, 'sent_at').reverse()[0]?.id);

const hasHighlightedMessages = computed(() => props.highlightMessageIds?.length > 0);

function isHighlightedMessage(id: number) {
  return props.highlightMessageIds?.includes(id);
}

function differenceIsGreaterThanInterval(sentAt: string, prevSentAt: string) {
  const difference = Math.abs(differenceInMinutes(parseJSON(sentAt), parseJSON(prevSentAt)));
  return difference >= MIN_INTERVAL_BETWEEN_MESSAGES_MINUTES;
}

function differenceIsLessThanInterval(sentAt: string, prevSentAt: string) {
  return !differenceIsGreaterThanInterval(sentAt, prevSentAt);
}

function isWithinInterval(message?: SmsData, otherMessage?: SmsData) {
  return (
    message?.sent_at &&
    otherMessage?.sent_at &&
    differenceIsLessThanInterval(message.sent_at, otherMessage.sent_at)
  );
}

function isOutsideInterval(message?: SmsData, otherMessage?: SmsData) {
  return !isWithinInterval(message, otherMessage);
}

function isSameDirection(message?: SmsData, otherMessage?: SmsData) {
  return message && otherMessage && message.direction.value === otherMessage.direction.value;
}

function isNotSameDirection(message?: SmsData, otherMessage?: SmsData) {
  return !isSameDirection(message, otherMessage);
}

function isLastOfDayOrLastOfDirection(message: SmsData, nextMessage?: SmsData) {
  const isLastMessageOfDay = nextMessage === undefined;
  const nextMessageIsAlternateDirection = nextMessage?.direction.value !== message.direction.value;

  return isLastMessageOfDay || nextMessageIsAlternateDirection;
}

function getGroupPlacement(
  currentMessage: SmsData,
  index: number,
  group: SmsData[]
): 'no-group' | 'first' | 'middle' | 'last' {
  const previousMessage = group?.[index - 1];
  const nextMessage = group?.[index + 1];

  if (
    isNotSameDirection(currentMessage, previousMessage) &&
    isNotSameDirection(currentMessage, nextMessage)
  ) {
    return 'no-group';
  }

  if (
    (isNotSameDirection(currentMessage, previousMessage) &&
      isWithinInterval(currentMessage, nextMessage)) ||
    (isSameDirection(currentMessage, nextMessage) &&
      isWithinInterval(currentMessage, nextMessage) &&
      isOutsideInterval(currentMessage, previousMessage))
  ) {
    return 'first';
  }

  if (
    isSameDirection(currentMessage, previousMessage) &&
    isSameDirection(currentMessage, nextMessage) &&
    differenceIsLessThanInterval(currentMessage.sent_at, previousMessage.sent_at) &&
    differenceIsLessThanInterval(currentMessage.sent_at, nextMessage.sent_at)
  ) {
    return 'middle';
  }

  if (
    isLastOfDayOrLastOfDirection(currentMessage, nextMessage) &&
    isWithinInterval(currentMessage, previousMessage)
  ) {
    return 'last';
  }

  return 'no-group';
}

function getGroupClasses(currentMessage: SmsData, index: number, group: SmsData[]) {
  const placement = getGroupPlacement(currentMessage, index, group);

  return [['middle', 'last'].includes(placement) && '!mt-0.5'];
}

function getMessageBubbleGroupClasses(currentMessage: SmsData, index: number, group: SmsData[]) {
  const placement = getGroupPlacement(currentMessage, index, group);

  return [
    ['middle', 'last'].includes(placement) && '!mt-0.5',
    placement === 'first' && {
      '!rounded-br-none': currentMessage.direction.value === 'outbound',
      '!rounded-bl-none': currentMessage.direction.value !== 'outbound'
    },
    placement === 'middle' && {
      '!rounded-tr-none !rounded-br-none': currentMessage.direction.value === 'outbound',
      '!rounded-tl-none !rounded-bl-none': currentMessage.direction.value !== 'outbound'
    },
    placement === 'last' && {
      '!rounded-tr-none': currentMessage.direction.value === 'outbound',
      '!rounded-tl-none': currentMessage.direction.value !== 'outbound'
    }
  ];
}

function shouldShowDateTime(currentMessage: SmsData, index: number, group: SmsData[]) {
  const placement = getGroupPlacement(currentMessage, index, group);

  return ['last', 'no-group'].includes(placement);
}
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col">
    <div class="flex-1 overflow-y-auto px-3 py-2 pt-16">
      <ThreadsEmptyState
        v-if="messages.length === 0"
        inline
        :title="isLoading ? 'Loading Messages' : 'No Messages'"
        :description="isLoading ? '' : 'There are no messages to display.'"
        :actionLabel="isLoading ? 'Loading...' : ''"
        :isLoading="isLoading"
      />

      <template v-else v-for="(messages, day) in groupedMessages" :key="day">
        <h3 class="mb-2 mt-4 text-center text-xs font-bold uppercase text-slate-600 first:mt-0">
          {{ day }}
        </h3>

        <Message
          v-for="(message, i) in messages as SmsData[]"
          :key="message.id"
          :showDateTime="shouldShowDateTime(message, i, messages as SmsData[])"
          :message="message"
          :class="[...getGroupClasses(message, i, messages as SmsData[]), 'mt-2']"
          :messageBubbleClasses="[
            getMessageBubbleGroupClasses(message, i, messages as SmsData[]),
            hasHighlightedMessages && [
              'scroll-mt-1',
              isHighlightedMessage(message.id) &&
                'border !border-amber-100 !bg-amber-400 !text-yellow-950 shadow-[0_0_10px] shadow-amber-400'
            ]
          ]"
          v-bind="{ 'data-group-placement': getGroupPlacement(message, i, messages as SmsData[]) }"
          :scrollIntoViewOnMount="
            highlightMessageIds[0] === message.id ||
            (scrollToLatestMessage && message.id === mostRecentSentMessageId)
          "
        />
      </template>
    </div>

    <div class="sticky bottom-0 bg-white">
      <RecipientErrors :errors="form.errors" />
      <ComposeMessageInput
        v-if="hasSmsEnabled && (thread.is_phone_number_recipient || thread.recipient?.sms_opted_in)"
        v-model="form.fields.body"
        v-model:attachments="form.fields.attachment_ids"
        v-model:scheduledSendDate="form.fields.scheduled_send_date"
        wrapperClass="border-t px-2 pb-3"
        :error="form.errors.body"
        :isLoading="form.processing"
        @send="form.submit"
      />
    </div>
  </div>
</template>
