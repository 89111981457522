<script setup lang="ts" generic="T extends SelectableValue">
import {
  ListboxContent,
  ListboxGroup,
  ListboxGroupLabel,
  ListboxItem,
  ListboxRoot
} from 'radix-vue';

import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import GroupedListboxRadio from './GroupedListboxRadio.vue';

defineProps<{
  group: {
    label: string;
    key: string;
    icon?: Component;
    options: Array<Selectable<T>>;
  };
}>();

const model = defineModel<T | null>();
</script>

<template>
  <ListboxRoot
    :modelValue="model ?? undefined"
    @update:modelValue="(v) => (model = v as unknown as T)"
  >
    <ListboxContent class="p-2">
      <ListboxGroup>
        <ListboxGroupLabel class="flex flex-row items-center gap-x-1.5">
          <component :is="group.icon" class="w-4 text-zinc-500" />
          <span class="text-xs font-semibold uppercase leading-5 text-zinc-500">{{
            group.label
          }}</span>
        </ListboxGroupLabel>
        <SelectBoxItem
          v-for="option in group.options"
          :as="ListboxItem"
          :key="`${option.label}-${option.value}`"
          :value="option.value"
          :disabled="model === option.value"
          class="py-2"
        >
          <GroupedListboxRadio :checked="model === option.value" />
          <span class="text-sm font-normal leading-5 text-zinc-700">{{ option.label }}</span>
        </SelectBoxItem>
      </ListboxGroup>
    </ListboxContent>
  </ListboxRoot>
</template>
