<script setup lang="ts">
import pluralize from 'pluralize';
import Alert from '@/components/alert/Alert.vue';

import CheckCircleIcon from '@/icons/line/check-circle.svg';
import QuestionCircleIcon from '@/icons/line/question-circle.svg';
import PlusCircleIcon from '@/icons/line/plus-circle.svg';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import { capitalize } from 'lodash';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';

const props = defineProps<{
  uploadRecord: App.Bulkinator.Data.UploadRecordData;
  uploadType: App.Bulkinator.Data.UploadTypeData;
}>();

const newMatchOption = computed(() => ({
  value: 'new',
  label: `Create new ${props.uploadType.record_name}`,
  description: `This record will create a new ${props.uploadType.record_name} in the system.`
}));

const form = useForm({
  url: route('upload-records.match.update', { uploadRecord: props.uploadRecord.id }),
  method: 'POST',
  fields: {
    status: props.uploadRecord.status,
    upload_record_match_id: props.uploadRecord.uploadRecordMatch?.id ?? null,
    upload_record_match: props.uploadRecord.uploadRecordMatch
      ? ({
          value: props.uploadRecord.uploadRecordMatch.id,
          label: props.uploadRecord.uploadRecordMatch.matchable?.label,
          description: props.uploadRecord.uploadRecordMatch.matchable?.description
        } as Selectable<SelectableValue>)
      : props.uploadRecord.status === 'new'
        ? newMatchOption.value
        : null
  },
  transform: (fields) => ({
    ...fields,
    upload_record_match_id:
      fields.upload_record_match?.value !== 'new' ? fields.upload_record_match?.value : null
  }),
  only: ['uploadRecord', 'uploadRecords'],
  preserveState: true,
  preserveScroll: true,
  updateInitials: true
});

watch(
  () => props.uploadRecord.uploadRecordMatch,
  (value) => {
    if (value) {
      form.fields.upload_record_match = {
        value: value.id,
        label: value.matchable?.label,
        description: value.matchable?.description
      } as Selectable<SelectableValue>;
    }
  }
);

const matchCount = computed(() => props.uploadRecord.matches?.length ?? 0);
const errorCount = computed(() => Object.keys(props.uploadRecord.validation_results ?? {}).length);

const alertData = computed(() => {
  return (
    {
      match: {
        color: 'success',
        label: 'Match found',
        description: `1 matching ${props.uploadType.record_name} was found. You can also create a new one if the match is not correct.`,
        icon: CheckCircleIcon
      },
      pending: {
        color: 'warning',
        label: `${pluralize('potential match', matchCount.value, true)}`,
        description: `${pluralize(
          `matching ${props.uploadType.record_name}`,
          matchCount.value,
          true
        )} were found. Please select or create a new one.`,
        icon: QuestionCircleIcon
      },
      new: {
        color: 'primary',
        label: 'New entry',
        description: `This record will create a new ${props.uploadType.record_name}.`,
        icon: PlusCircleIcon
      },
      error: {
        color: 'danger',
        label: `${pluralize('error', errorCount.value, true)} found`,
        description: `Please correct the validation errors before continuing with the import.`,
        icon: ExclamationCircleIcon
      }
    }[props.uploadRecord.status] ?? null
  );
});

const options = computed(() => {
  const options = (props.uploadRecord.matches?.map((match) => ({
    value: match.id,
    label: match.matchable?.label,
    description: match.matchable?.description
  })) ?? []) as Selectable<SelectableValue>[];

  return [...options, newMatchOption.value];
});

function handleMatchRecordChange(value: Selectable<SelectableValue> | null) {
  if (value?.value === 'new') {
    form.fields.upload_record_match = newMatchOption.value;
    form.fields.status = 'new';
  } else {
    form.fields.upload_record_match = value;
    form.fields.status = 'match';
  }

  form.submit();
}
</script>

<template>
  <div class="flex flex-col gap-3 border-b border-zinc-200 px-5 pb-3 pt-6">
    <h4 class="text-xs font-bold uppercase text-zinc-500">
      Matching {{ capitalize(uploadType.record_name) }}
    </h4>

    <div class="space-y-1.5">
      <div v-if="options.length && uploadRecord.status !== 'error'">
        <ListboxInput
          :isLoading="form.processing"
          :options="options"
          :modelValue="form.fields.upload_record_match"
          :fixedHeight="false"
          @update:modelValue="handleMatchRecordChange"
        />
      </div>

      <Alert
        v-if="alertData"
        :title="alertData.label"
        :description="alertData.description"
        :icon="alertData.icon"
        :color="alertData.color as any"
        variant="soft"
        alignment="horizontal"
      />
    </div>
  </div>
</template>
