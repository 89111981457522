export function formatNumber(number: number | string | null | undefined): string {
  if (number === null || number === undefined) {
    return '';
  }

  const parsed = Number(number);
  if (Number.isNaN(parsed)) {
    return String(number);
  }

  return new Intl.NumberFormat('en-US').format(parsed);
}
