<script setup lang="tsx">
import { RouterLink } from 'hybridly/vue';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import { MenuItemGroup, useNavigation } from '@/hooks/useNavigation';
import { useAuth } from '@/hooks/useAuth';
import { usePageMeta } from '@/hooks/usePageMeta';
import { useTailwind } from '@/hooks/useTailwind';
import { useWindowSize } from '@vueuse/core';

import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import BulkCreateCollegeApplicationDialog from '@/domains/students/components/dialogs/BulkCreateCollegeApplicationDialog.vue';
import BulkCreateStudentScholarshipDialog from '@/domains/students/components/dialogs/BulkCreateStudentScholarshipDialog.vue';
import BulkCreateStudentInteractionDialog from '@/domains/students/components/dialogs/BulkCreateStudentInteractionDialog.vue';
import BulkCreateStudentNoteDialog from '@/domains/students/components/dialogs/BulkCreateStudentNoteDialog.vue';
import BulkUpdateFinancialAidDialog from '@/domains/students/components/dialogs/BulkUpdateFinancialAidDialog.vue';
import Button from '@/components/button/Button.vue';
import HeaderButtonNav from '@/components/header/HeaderButtonNav.vue';
import Collapsible from '@/components/collapsible/Collapsible.vue';
import IconButton from '@/components/button/IconButton.vue';
import ImpersonatingBanner from '@/components/impersonating/ImpersonatingBanner.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuButton from '@/components/button/MenuButton.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import NavigationItem from '@/components/navigationItem/NavigationItem.vue';
import SessionTimeout from '@/components/session/SessionTimeout.vue';
import ToastManager from '@/components/toastManager/ToastManager.vue';
import UserMenuItem from '@/components/user/UserMenuItem.vue';

import IconEnvelope from '@/icons/line/envelope.svg';
import IconExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import IconPlus from '@/icons/line/plus.svg';
import IconSetting from '@/icons/line/setting.svg';
import IconSignOut from '@/icons/line/sign-out-alt.svg';
import IconWebSectionAlt from '@/icons/line/web-section-alt.svg';
import MegaphoneIcon from '@/icons/line/megaphone.svg';
import CommentPlus from '@/icons/line/comment-plus.svg';
import University from '@/icons/line/university.svg';
import Bill from '@/icons/line/bill.svg';
import Notes from '@/icons/line/notes.svg';
import DollarSign from '@/icons/line/dollar-sign.svg';

import LogoCacGrace from '@/images/logo-cac-grace.svg';
import LogoIcon from '@/images/logo-icon.svg';

const { breadcrumbs } = usePageMeta();
const { theme } = useTailwind();
const { user, hasPermission } = useAuth();

const [isInteractionOpen, toggleInteractionOpen] = useToggle(false);
const [isCollegeApplicationOpen, toggleCollegeApplicationOpen] = useToggle(false);
const [isScholarshipOpen, toggleScholarshipOpen] = useToggle(false);
const [isFinancialAidOpen, toggleFinancialAidOpen] = useToggle(false);
const [isNoteOpen, toggleNoteOpen] = useToggle(false);

const navigationGroups = useNavigation();

const { width: windowWidth } = useWindowSize();

const isSmallScreen = computed(() => windowWidth.value <= parseInt(theme?.screens.md ?? '0px'));
const isSidebarCollapsed = useSessionStorage('sidebarCollapsed', isSmallScreen.value);
const userMenuItemTrigger = ref<HTMLElement | null>(null);

useBackForward({
  reload: true
});

// Consider moving to a hook, or use VueUse: https://vueuse.org/core/useMediaQuery/#usemediaquery
const mediaQuery = window.matchMedia('(max-width: ' + theme.screens.md + ')');
function handleMediaQueryChange(mediaQuery: MediaQueryList): void {
  isSidebarCollapsed.value = !!mediaQuery.matches;
}
mediaQuery.addEventListener('change', (e: MediaQueryListEvent) => {
  handleMediaQueryChange(e.currentTarget as MediaQueryList);
});

if (user.value) {
  window.Canny('identify', {
    appID: '66f58171eb9761b4e15b11ba',
    user: {
      email: user.value.email,
      name: user.value.name,
      id: user.value.id,
      created: new Date(user.value.created_at).toISOString(),
      customFields: {
        environment: window.Grace.env
      }
    }
  });
}

function toggleSidebar() {
  isSidebarCollapsed.value = !isSidebarCollapsed.value;
}

function getNavGroupComponent(navGroup: MenuItemGroup) {
  if (
    navGroup.label &&
    (!isSidebarCollapsed.value || (isSidebarCollapsed.value && isSmallScreen.value))
  ) {
    return <Collapsible title={navGroup.label as string} isOpen />;
  }

  return <div></div>;
}

function handleLogout() {
  router.post(route('logout.store'));
}
</script>

<template>
  <div
    class="flex h-full transition-transform print:h-auto print:overflow-visible"
    :class="{ '-translate-x-62 transform': isSidebarCollapsed && isSmallScreen }"
  >
    <aside
      class="flex shrink-0 flex-col border-r border-slate-200 bg-slate-50 text-gray-100 transition-all duration-300 ease-in-out print:hidden"
      :class="{
        'md:w-18': isSidebarCollapsed && !isSmallScreen,
        'w-62': !isSidebarCollapsed || (isSidebarCollapsed && isSmallScreen)
      }"
    >
      <router-link
        :href="route('dashboard.show')"
        class="flex py-4 hover:bg-slate-100"
        :class="{
          'justify-center': isSidebarCollapsed && !isSmallScreen,
          'justify-start pl-4': !isSidebarCollapsed || (isSidebarCollapsed && isSmallScreen)
        }"
      >
        <LogoCacGrace
          v-if="!isSidebarCollapsed || isSmallScreen"
          class="h-12"
          @click="
            () => {
              isSmallScreen && toggleSidebar();
            }
          "
        />
        <LogoIcon v-else class="h-12" />
      </router-link>

      <div class="flex justify-center border-b border-t px-3 py-4">
        <slot name="main-action">
          <template v-if="isSidebarCollapsed && !isSmallScreen">
            <MenuButton
              v-if="hasPermission('add-student-info-from-sidebar')"
              buttonLabel="Add"
              block
            >
              <template #trigger>
                <IconButton
                  :variant="ButtonVariant.solid"
                  :color="ButtonColor.primary"
                  :icon="IconPlus"
                  ariaLabel="Add student info"
                />
              </template>
              <MenuItem
                v-if="hasPermission('create-interaction')"
                :iconLeft="CommentPlus"
                label="Interaction"
                @click="() => toggleInteractionOpen()"
              />
              <MenuItem
                v-if="hasPermission('import-student')"
                :iconLeft="University"
                label="College application"
                @click="() => toggleCollegeApplicationOpen()"
              />
              <MenuItem
                v-if="hasPermission('import-student')"
                :iconLeft="Bill"
                label="Scholarship"
                @click="() => toggleScholarshipOpen()"
              />
              <MenuItem
                :iconLeft="DollarSign"
                label="Financial aid application"
                @click="() => toggleFinancialAidOpen()"
              />
              <MenuItem
                v-if="hasPermission('create-note')"
                :iconLeft="Notes"
                label="Note"
                @click="() => toggleNoteOpen()"
              />
            </MenuButton>
            <IconButton
              v-else
              :variant="ButtonVariant.solid"
              :color="ButtonColor.primary"
              :icon="IconPlus"
              :href="route('report-templates.index')"
              ariaLabel="Add report"
            />
          </template>
          <template v-else>
            <MenuButton
              v-if="hasPermission('add-student-info-from-sidebar')"
              buttonLabel="Add"
              block
            >
              <MenuItem
                v-if="hasPermission('create-interaction')"
                :iconLeft="CommentPlus"
                label="Interaction"
                @click="
                  () => {
                    toggleInteractionOpen();
                    isSmallScreen && toggleSidebar();
                  }
                "
              />
              <MenuItem
                v-if="hasPermission('import-student')"
                :iconLeft="University"
                label="College application"
                @click="
                  () => {
                    toggleCollegeApplicationOpen();
                    isSmallScreen && toggleSidebar();
                  }
                "
              />
              <MenuItem
                v-if="hasPermission('import-student')"
                :iconLeft="Bill"
                label="Scholarship"
                @click="
                  () => {
                    toggleScholarshipOpen();
                    isSmallScreen && toggleSidebar();
                  }
                "
              />
              <MenuItem
                :iconLeft="DollarSign"
                label="Financial aid application"
                @click="
                  () => {
                    toggleFinancialAidOpen();
                    isSmallScreen && toggleSidebar();
                  }
                "
              />
              <MenuItem
                v-if="hasPermission('create-note')"
                :iconLeft="Notes"
                label="Note"
                @click="
                  () => {
                    toggleNoteOpen();
                    isSmallScreen && toggleSidebar();
                  }
                "
              />
            </MenuButton>
            <Button
              v-else
              :color="ButtonColor.primary"
              :href="route('report-templates.index')"
              :variant="ButtonVariant.solid"
              @click="() => isSmallScreen && toggleSidebar()"
              class="w-full"
              >New Report</Button
            >
          </template>
        </slot>
      </div>

      <div
        class="flex flex-1 flex-col gap-2 overflow-y-auto px-3 py-4"
        :class="{
          'items-center divide-y divide-slate-300 px-0': isSidebarCollapsed && !isSmallScreen
        }"
      >
        <component
          :is="getNavGroupComponent(navigationGroup)"
          v-for="(navigationGroup, i) in navigationGroups"
          :key="navigationGroup.label || 'default'"
          class="gap-y-0.5"
          :class="{
            'pt-2': isSidebarCollapsed && i > 0 && !isSmallScreen
          }"
        >
          <NavigationItem
            class="text-sm"
            v-for="navigationItem in navigationGroup.items"
            :key="navigationItem.label"
            :label="navigationItem.label"
            :icon="navigationItem.icon"
            :href="route(navigationItem.route)"
            :isActive="navigationItem.matches || router.matches(navigationItem.route)"
            :isCollapsed="isSidebarCollapsed && !isSmallScreen"
            :badgeCount="navigationItem.badgeCount"
            @click="() => isSmallScreen && toggleSidebar()"
          />
        </component>
      </div>

      <Menu
        contentClasses="w-60"
        :contentOptions="{
          sideOffset:
            isSidebarCollapsed && !isSmallScreen
              ? 4
              : -Math.abs((userMenuItemTrigger?.clientHeight ?? 80) - 20),
          side: isSidebarCollapsed ? 'right' : 'bottom'
        }"
      >
        <template #trigger>
          <UserMenuItem
            v-if="user"
            ref="userMenuItemTrigger"
            :user="user"
            :hideName="isSidebarCollapsed && !isSmallScreen"
            class="border-t border-slate-200 px-4 py-4 hover:cursor-pointer hover:bg-slate-100"
          />
        </template>

        <template #header>
          <UserMenuItem v-if="user" :user="user" />
        </template>

        <template #footer>
          <p class="text-center text-xs text-slate-400">Logged in as {{ user?.email }}</p>
        </template>

        <MenuItem label="My Account" :iconLeft="IconSetting" :href="route('account.edit')" />
        <MenuItem
          :iconLeft="IconExternalLinkAlt"
          href="https://collegeadvisingcorps.org/"
          isExternalLink
          label="AdvisingCorps.org"
        />
        <MenuItem
          data-canny-link
          :iconLeft="MegaphoneIcon"
          href="https://grace.canny.io"
          target="_blank"
          isExternalLink
          label="Give feedback on GRACE"
        />
        <MenuItem
          :iconLeft="IconEnvelope"
          href="mailto:grace@advisingcorps.org"
          isExternalLink
          label="Contact Us"
        />
        <MenuItem label="Logout" :iconLeft="IconSignOut" @click="handleLogout" />
      </Menu>
    </aside>

    <div
      class="relative flex flex-1 flex-col md:min-w-0"
      :class="{ 'min-w-screen': isSmallScreen }"
    >
      <ImpersonatingBanner />

      <header
        class="sticky top-0 z-50 flex items-center gap-1 border-b border-slate-200 bg-white p-3 print:hidden"
      >
        <IconButton
          :ariaLabel="isSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'"
          :icon="IconWebSectionAlt"
          variant="invisible"
          @click="toggleSidebar"
          size="md"
          :color="ButtonColor.slate"
        />

        <Breadcrumbs :breadcrumbs="breadcrumbs" />

        <HeaderButtonNav :isSmallScreen="isSmallScreen" />
      </header>
      <div class="flex-1 overflow-y-auto" scroll-region>
        <div class="h-full min-h-full">
          <!-- Main content goes here -->
          <slot />
        </div>
        <Teleport to="#toast-manager">
          <div class="fixed bottom-8 z-[100] mx-auto flex w-full justify-center px-8">
            <ToastManager class="w-full max-w-3xl" />
          </div>
        </Teleport>
      </div>
    </div>

    <SessionTimeout />
  </div>
  <BulkCreateStudentInteractionDialog
    v-if="isInteractionOpen"
    isOpen
    @onCancel="() => toggleInteractionOpen()"
    @onClose="() => toggleInteractionOpen()"
  />
  <BulkCreateCollegeApplicationDialog
    v-if="isCollegeApplicationOpen"
    isOpen
    @onCancel="() => toggleCollegeApplicationOpen()"
    @onClose="() => toggleCollegeApplicationOpen()"
  />
  <BulkCreateStudentScholarshipDialog
    v-if="isScholarshipOpen"
    isOpen
    @onCancel="() => toggleScholarshipOpen()"
    @onClose="() => toggleScholarshipOpen()"
  />
  <BulkUpdateFinancialAidDialog
    v-if="isFinancialAidOpen"
    isOpen
    @onCancel="() => toggleFinancialAidOpen()"
    @onClose="() => toggleFinancialAidOpen()"
  />
  <BulkCreateStudentNoteDialog
    v-if="isNoteOpen"
    isOpen
    @onCancel="() => toggleNoteOpen()"
    @onClose="() => toggleNoteOpen()"
  />
</template>
