<script setup lang="tsx">
import { UploadFormValues } from '@/domains/bulkinator/components/forms/useUploadSessionFormWizard';
import StatusIndicatorCell from '@/domains/bulkinator/components/table/StatusIndicatorCell.vue';
import LockedCell from '@/domains/bulkinator/components/table/LockedCell.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import { SelectItem, SelectItemText, SelectLabel, SelectItemIndicator } from 'radix-vue';
import Check from '@/icons/line/check.svg';
import { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  form: ReturnType<typeof useForm<UploadFormValues>>;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  columns: Array<{ label: string; value: string }>;
  headers: string[];
  showOnlyUnmapped: boolean;
}>();

function hasMapping(header: string) {
  return !!props.form.fields.column_mappings[header];
}

function getIndicator(header: string) {
  const error = props.form.errors.column_mappings?.[header];

  if (error) {
    return {
      status: 'error',
      message: error
    };
  }

  if (hasMapping(header)) {
    return {
      status: 'match',
      message: 'This column has been mapped.'
    };
  }
  return {
    status: 'pending',
    message: `This column is unmapped and will be stored but not displayed in the ${props.uploadType.id} upload session table view.`
  };
}

function handleSelect(v: Selectable<string> | null, header: string) {
  if (!v || v?.value === props.form.fields.column_mappings[header]?.value) {
    delete props.form.fields.column_mappings[header];
  } else {
    props.form.fields.column_mappings[header] = v;
  }
}

const options = computed(() =>
  props.columns
    .map((col) => ({ label: col.label, value: col.value }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

const selectedOptionsSet = computed(() => {
  return Object.entries(props.form.fields.column_mappings).reduce((acc, curr) => {
    const [, value] = curr;
    acc.add(value.value);
    return acc;
  }, new Set<string>());
});

function isDisabled(option: Selectable<string>, header: string): boolean {
  return (
    selectedOptionsSet.value.has(option.value) &&
    props.form.fields.column_mappings[header]?.value !== option.value
  );
}

const headersToShow = computed(() =>
  props.showOnlyUnmapped ? props.headers.filter((header) => !hasMapping(header)) : props.headers
);
</script>

<template>
  <table class="border-collapse border border-zinc-200 bg-zinc-50" cellpadding="0">
    <colgroup>
      <col class="w-10" />
      <col span="2" />
    </colgroup>

    <thead>
      <tr class="h-12">
        <th
          class="rounded-tl-lg border-b border-r text-left text-xs uppercase leading-5 text-zinc-500"
        ></th>
        <th class="border-b border-r text-left text-xs uppercase leading-5 text-zinc-500">
          <PaddedCellContent>Columns in your document</PaddedCellContent>
        </th>
        <th class="rounded-tr-lg border-b text-left text-xs uppercase leading-5 text-zinc-500">
          <PaddedCellContent>{{ uploadType.id }} core item attribute</PaddedCellContent>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="headersToShow.length > 0">
        <tr v-for="header in headersToShow" :key="header" class="group/mapping-row">
          <td class="h-[52px] border-b border-r group-last/mapping-row:border-0">
            <StatusIndicatorCell :status="getIndicator(header).status" :uploadType="uploadType">
              {{ getIndicator(header).message }}
            </StatusIndicatorCell>
          </td>
          <td class="h-[52px] border-b border-r group-last/mapping-row:border-0">
            <LockedCell :value="header" class="text-sm font-medium leading-5 text-zinc-500" />
          </td>
          <td class="[h-[52px] border-b group-last/mapping-row:border-0">
            <div :class="{ 'bg-amber-100': !hasMapping(header), 'bg-zinc-50': hasMapping(header) }">
              <!-- Need to recreate the select component on value change otherwise we can't unset the selected value -->
              <!-- i.e. clicking on the currently selected option will close the select and not trigger the update event -->
              <ListboxInput
                :key="form.fields.column_mappings[header]?.value ?? header"
                contentWidth="min-w-[--radix-select-trigger-width]"
                :options="options"
                placeholder=""
                :modelValue="form.fields.column_mappings[header]"
                @update:modelValue="(v) => handleSelect(v, header)"
                :fixedHeight="false"
                inputClasses="h-[52px] rounded-sm border border-none !bg-inherit"
              >
                <template #options>
                  <SelectLabel
                    class="border-b border-zinc-200 pb-4 pt-2 text-center text-sm font-normal leading-5 text-zinc-500"
                    v-if="!form.fields.column_mappings[header]"
                  >
                    No matching results in the system
                  </SelectLabel>
                  <SelectBoxItem
                    :as="SelectItem"
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                    :disabled="isDisabled(option, header)"
                    class="group/mapping-option flex-row items-center justify-between"
                  >
                    <SelectItemText>
                      <div
                        class="py-2 font-semibold leading-5 text-slate-900 group-data-[disabled]/mapping-option:text-slate-500 group-data-[disabled]/mapping-option:opacity-80"
                      >
                        {{ option.label }}
                      </div>
                    </SelectItemText>
                    <SelectItemIndicator
                      v-if="form.fields.column_mappings[header]?.value === option.value"
                      class="w-4 text-[#6466F1]"
                    >
                      <Check />
                    </SelectItemIndicator>
                  </SelectBoxItem>
                </template>
              </ListboxInput>
            </div>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="3">
            <PaddedCellContent class="bg-white py-5 text-center text-sm leading-5"
              >All columns have been mapped</PaddedCellContent
            >
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
