import { type Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';
import tailwindForms from '@tailwindcss/forms';
import containerQueries from '@tailwindcss/container-queries';

/** @type {import('tailwindcss').Config} */
export default {
  content: ['./resources/**/*.{js,ts,vue,blade.php,stories.mdx,stories.js,jsx}'],
  safelist: ['fill-current'],
  theme: {
    extend: {
      screens: {
        xs: { min: '361px' },
        tall: { raw: '(min-height: 601px)' }
      },
      colors: {
        primary: {
          50: '#fcedfc',
          100: '#F5D7F5',
          200: '#EBBCEB',
          300: '#d199d1',
          400: '#C48FC4',
          500: '#B87BB8',
          600: '#AB68AB',
          700: '#9E579E',
          800: '#803780',
          900: '#601E60',
          950: '#431543'
        },
        secondary: {
          50: '#f2f6fc',
          100: '#d6e9ff',
          200: '#B2DAFF',
          300: '#7cbbeb',
          400: '#5cb1e5',
          500: '#3dabe0',
          600: '#2a87c9',
          700: '#1a65b0',
          800: '#0f4499',
          900: '#123070',
          950: '#0f1c45'
        },
        green: {
          50: '#e9fdef'
        }
      },
      animation: {
        'overlay-show': 'overlay-show 300ms ease-in-out',
        'content-show': 'content-show 400ms ease-in-out',
        slideout: 'slideout 400ms ease-in-out',
        'floating-action-drawer-show': 'floating-action-drawer-show 400ms ease-in-out',
        'slide-down-and-fade': 'slide-down-and-fade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-left-and-fade': 'slide-left-and-fade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-up-and-fade': 'slide-up-and-fade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-right-and-fade': 'slide-right-and-fade 400ms cubic-bezier(0.16, 1, 0.3, 1)'
      },
      fontFamily: {
        sans: ['Lato', ...defaultTheme.fontFamily.sans]
      },
      fontSize: {
        xs: '0.6875rem',
        sm: '0.8125rem',
        base: '0.9375rem',
        lg: '1.125rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '3.75rem',
        '7xl': '4.5rem',
        '8xl': '6rem',
        '9xl': '8rem'
      },
      keyframes: {
        'overlay-show': {
          from: { opacity: '0' },
          to: { opacity: '1' }
        },
        'content-show': {
          from: { opacity: '0', transform: 'translate(-50%, -48%) scale(0.96)' },
          to: { opacity: '1', transform: 'translate(-50%, -50%) scale(1)' }
        },
        slideout: {
          from: { transform: 'translateX(400px)' },
          to: { transform: 'translateX(0)' }
        },
        'floating-action-drawer-show': {
          from: { transform: 'translateY(100%)' },
          to: { transform: 'translateX(0)' }
        },
        'slide-down-and-fade': {
          from: { opacity: '0', transform: 'translateY(-2px)' },
          to: { opacity: '1', transform: 'translateY(0)' }
        },
        'slide-left-and-fade': {
          from: { opacity: '0', transform: 'translateX(2px)' },
          to: { opacity: '1', transform: 'translateX(0)' }
        },
        'slide-up-and-fade': {
          from: { opacity: '0', transform: 'translateY(2px)' },
          to: { opacity: '1', transform: 'translateY(0)' }
        },
        'slide-right-and-fade': {
          from: { opacity: '0', transform: 'translateX(-2px)' },
          to: { opacity: '1', transform: 'translateX(0)' }
        }
      },
      minHeight: {
        36: '9rem'
      },
      minWidth: {
        16: '4rem',
        38: '9.5rem',
        72: '18rem',
        screen: '100vw'
      },
      ringWidth: {
        '3': '3px'
      },
      width: {
        '18': '4.5rem',
        '62': '15.5rem'
      },
      maxWidth: {
        86: '21.5rem',
        108: '27rem',
        115: '28.75rem',
        142: '35.5rem'
      },
      gridTemplateColumns: {
        'definition-list': 'auto 1fr'
      },
      scale: {
        '80': '0.8',
        '85': '0.85',
        '120': '1.2'
      },
      spacing: {
        '3.5': '0.875rem',
        '4.5': '1.125rem',
        '5.5': '1.375rem',
        '10.5': '2.375rem',
        '13': '3.25rem',
        '23': '5.75rem',
        '61': '15.25rem',
        '62': '15.5rem',
        '100': '25rem'
      },
      transitionProperty: {
        button: 'background-color, border-color, box-shadow',
        control: 'background-color, border-color, box-shadow',
        input: 'background-color, border-color, box-shadow',
        menu: 'opacity, margin',
        toast: 'opacity, transform'
      }
    }
  },
  plugins: [tailwindForms, containerQueries]
} satisfies Config;
