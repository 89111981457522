<script setup lang="ts">
import Badge, { BadgeProps } from '@/components/badge/Badge.vue';
import { StepStatus } from './GuidedStep';

import Check from '@/icons/line/check.svg';

export interface Step {
  label: string;
  description?: string;
}

const props = defineProps<
  Step & {
    isFirst: boolean;
    isLast: boolean;
    step: number;
    status: StepStatus;
    direction: 'horizontal' | 'vertical';
    showStepNumber: boolean;
    showBadge: boolean;
  }
>();

const badgeLabel = computed(
  () =>
    ({
      [StepStatus.Complete]: 'Completed',
      [StepStatus.InProgress]: 'In Progress',
      [StepStatus.Pending]: 'Pending'
    })[props.status]
);

const badgeColor = computed(
  () =>
    ({
      [StepStatus.Complete]: 'success',
      [StepStatus.InProgress]: 'indigo',
      [StepStatus.Pending]: 'primary'
    })[props.status] as BadgeProps['color']
);
</script>

<template>
  <div
    class="flex flex-1 gap-2"
    :class="{
      'flex-row': direction === 'vertical',
      'flex-col text-center': direction === 'horizontal'
    }"
  >
    <div
      class="flex items-center gap-1"
      :class="{
        'flex-col': direction === 'vertical',
        'flex-row': direction === 'horizontal'
      }"
    >
      <div
        v-if="direction === 'horizontal'"
        class="h-px w-full grow"
        :class="{
          'bg-slate-300': !isFirst && status === StepStatus.Pending,
          'bg-secondary-800':
            !isFirst && [StepStatus.InProgress, StepStatus.Complete].includes(status)
        }"
      />

      <div class="shrink-0">
        <div v-if="status === StepStatus.Complete" class="rounded-full bg-secondary-800">
          <Check class="h-4 w-4 text-white" />
        </div>
        <div
          v-else
          class="h-4 w-4 rounded-full bg-current p-0.5"
          :class="{
            'text-slate-300': props.status === StepStatus.Pending,
            'text-secondary-800': props.status !== StepStatus.Pending
          }"
        >
          <div class="h-full w-full rounded-full border border-white bg-current" />
        </div>
      </div>

      <div
        class="grow"
        :class="{
          'bg-slate-300': !isLast && [StepStatus.Pending, StepStatus.InProgress].includes(status),
          'bg-secondary-800': status === StepStatus.Complete,
          'w-px': direction === 'vertical',
          'h-px w-full': direction === 'horizontal'
        }"
      />
    </div>
    <div class="flex flex-col gap-1.5 px-3">
      <div
        v-if="showStepNumber"
        class="text-xs font-bold uppercase leading-3"
        :class="{
          'text-indigo-700': props.status === StepStatus.InProgress,
          'text-slate-500': props.status !== StepStatus.InProgress
        }"
      >
        Step {{ step }}
      </div>
      <div>
        <div class="text-base font-semibold leading-5 text-slate-600">{{ label }}</div>
        <div v-if="description" class="text-sm leading-4 text-slate-500">{{ description }}</div>
      </div>
      <div v-if="showBadge">
        <Badge :color="badgeColor" :label="badgeLabel" size="sm" variant="soft" />
      </div>
    </div>
  </div>
</template>
