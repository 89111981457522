<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import { useTemplateRef } from 'vue';
import { HybridRequestOptions } from '@hybridly/core';
import DateTime from '@/components/dateTime/DateTime.vue';

defineSlots<{
  prepend: void;
  appendBody: void;
}>();

const props = defineProps<
  {
    isActive: boolean;
    title?: string;
    date?: string;
    body?: string;
  } & {
    href: string;
    preserveScroll?: boolean;
    preserveState?: boolean;
    options?: Omit<HybridRequestOptions, 'data' | 'url' | 'method'>;
    disabled?: boolean;
    preload?: boolean | 'hover' | 'mount';
  }
>();

const rootEl = useTemplateRef<ComponentPublicInstance>('rootEl');
const bounding = useElementBounding(rootEl);
const parent = useParentElement();

onMounted(() => {
  if (props.isActive && rootEl.value && parent.value) {
    const parentScrollTop = parent.value.scrollTop;
    const parentScrollBottom = parentScrollTop + parent.value.clientHeight;

    if (bounding.top.value < parentScrollTop || bounding.bottom.value > parentScrollBottom) {
      rootEl.value.$el.scrollIntoView({ block: 'nearest' });
    }
  }
});
</script>

<template>
  <RouterLink
    :href
    :options
    :preload
    :disabled
    :preserveScroll
    :preserveState
    ref="rootEl"
    :class="[
      'flex items-start pb-3 pl-3 pr-4 pt-3.5 hover:cursor-pointer hover:bg-zinc-50',
      isActive ? 'bg-zinc-50' : ''
    ]"
  >
    <div class="flex max-w-full flex-1 items-start">
      <slot name="prepend" />
      <div class="flex flex-1 flex-col space-y-0.5 overflow-x-hidden">
        <header class="flex justify-between">
          <h3 class="text-sm font-bold leading-tight text-zinc-600">
            {{ title }}
          </h3>
          <DateTime v-if="date" :date class="text-xs font-medium text-zinc-500" />
        </header>
        <p class="max-w-full truncate text-sm leading-relaxed text-zinc-600">
          {{ body }}
        </p>
        <slot name="appendBody" />
      </div>
    </div>
  </RouterLink>
</template>
