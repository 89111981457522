<script setup lang="ts">
type AutomatedOutboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;

import { NodeProps } from '@vue-flow/core';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import NodeAddPopover from './NodeAddPopover.vue';
import NodeCard from '@/components/automated-campaigns/NodeCard.vue';
import NodeOutline from '@/components/automated-campaigns/NodeOutline.vue';

import EnvelopeIcon from '@/icons/line/envelope.svg';
import MessageIcon from '@/icons/line/message.svg';
import SlidersVAltIcon from '@/icons/line/slider-v-alt.svg';

const props = defineProps<NodeProps<AutomatedOutboundMessageData> & { isRoot?: boolean }>();

const formattedSendDate = computed(() => {
  return formatDateTime(props.data.scheduled_send_date);
});

const inboundMessageForm = useForm({
  fields: {
    outbound_message_id: props.data.id
  },
  method: 'post',
  url: route('sms.inbound-messages.store', { campaign: props.data.campaign_id })
});

async function handleAddInboundMessage(closePopover: () => void) {
  await inboundMessageForm.submit();

  closePopover();
}
</script>

<template>
  <NodeOutline
    class="px-2 pb-6 pt-2"
    :hasAlerts="!data.is_valid_node"
    :validationResults="data.validation_results as Record<string, string[]>"
    :selected="selected"
  >
    <NodeCard :label="data.name" :isRoot>
      <template #icon>
        <div class="inline-block rounded bg-sky-50 p-1.5">
          <EnvelopeIcon v-if="isRoot" class="h-4 w-4 text-sky-800" />
          <MessageIcon v-else class="h-4 w-4 text-sky-800" />
        </div>
      </template>
      <template #config>
        <Badge
          v-if="data.filter_count"
          :label="data.filter_count"
          size="xs"
          variant="soft"
          rounded
          color="secondary"
          :iconLeft="SlidersVAltIcon"
        />
      </template>
      <template #badges>
        <slot name="badges">
          <template v-if="isRoot">
            <Badge
              v-if="formattedSendDate"
              :label="formattedSendDate"
              color="primary"
              size="xs"
              variant="soft"
            />
            <p v-else class="text-xs leading-none text-zinc-500">No scheduled send date set yet.</p>
          </template>
        </slot>
      </template>
    </NodeCard>

    <template #addButton>
      <NodeAddPopover>
        <template #default="{ closePopover }">
          <Button
            :color="ButtonColor.primary"
            :isLoading="inboundMessageForm.processing"
            class="block w-full"
            size="xs"
            variant="soft"
            @click="handleAddInboundMessage(closePopover)"
            >Reply Option</Button
          >
        </template>
      </NodeAddPopover>
    </template>
  </NodeOutline>
</template>
