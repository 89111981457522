<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import IconAngleRight from '@/icons/line/angle-right.svg';
import IconCommentAltMessage from '@/icons/line/comment-alt-message.svg';
import IconEnvelope from '@/icons/line/envelope.svg';

defineProps<{
  channels: Array<App.Auth.Data.TwoFactorChannelData>;
}>();

const sendCode = useForm({
  method: 'POST',
  fields: {}
});

function channelIcon(channelType: string): Component {
  switch (channelType) {
    case 'email':
      return IconEnvelope;
  }
  return IconCommentAltMessage;
}

function handleSendCode(selectedChannel: App.Auth.Data.TwoFactorChannelData) {
  sendCode.submitWith({
    url: route('two-factor.deliver-code.store', {
      channel: selectedChannel.id
    })
  });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/one-column-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Two-factor authentication"
    description="Two factor authentication protects your account by requiring an additional code when you log in on a device that we don’t recognise."
  >
    <AuthForm>
      <div class="space-y-5">
        <h2 class="text-xs font-bold uppercase text-slate-500">Choose your security method</h2>

        <ul class="flex w-full flex-col gap-2">
          <li v-for="channel in channels" :key="channel.id" class="flex gap-2">
            <Button
              :color="ButtonColor.slate"
              :iconLeft="channelIcon(channel.channelType)"
              :size="ButtonSize.lg"
              :variant="ButtonVariant.outlined"
              class="w-full"
              @click="handleSendCode(channel)"
            >
              <span class="w-[105px]">{{ channel.redactedDestination }}</span>
              <Badge variant="solid" :label="channel.channelTypeDisplayName" size="sm" />
              <span class="flex flex-1 justify-end">
                <IconAngleRight class="h-4 w-4" />
              </span>
            </Button>
          </li>
        </ul>
      </div>

      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.slate"
            :variant="ButtonVariant.invisible"
            @click="router.post(route('logout.store'))"
            :size="ButtonSize.lg"
          >
            Sign out
          </Button>
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
