<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import CommentsAltIcon from '@/icons/line/comments-alt.svg';
import CommentAltIcon from '@/icons/line/comment-alt.svg';
import UpcomingMessageData = App.Sms.Data.UpcomingMessageData;
import CalendarIcon from '@/icons/line/calendar.svg';
import MegaphoneIcon from '@/icons/line/megaphone.svg';
import DateTime from '@/components/dateTime/DateTime.vue';
import Button from '@/components/button/Button.vue';
import { useAuth } from '@/hooks/useAuth';
import pluralize from 'pluralize';
import Collapsible from '@/components/collapsible/Collapsible.vue';
import IconAngleRight from '@/icons/line/angle-right.svg';
import IconEditPencil from '@/icons/line/edit.svg';
import IconTrash from '@/icons/line/trash.svg';
import IconButton from '@/components/button/IconButton.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import AutomatedOutboundMessageOverrideData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageOverrideData;
import AutomatedOutboundMessageOverrideSlideout from '@/domains/sms/components/AutomatedOutboundMessageOverrideSlideout.vue';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

const props = defineProps<{
  message: UpcomingMessageData;
}>();

const emit = defineEmits<{
  upcomingMessagesUpdated: [];
}>();

const badge = computed(() => {
  return {
    scheduled: {
      icon: CommentsAltIcon,
      label: 'Scheduled Message'
    },
    campaign: {
      icon: MegaphoneIcon,
      label: 'Campaign Message'
    }
  }[props.message.type];
});

const { hasPermission } = useAuth();

const isEditableCampaign = computed(() => {
  return props.message.type === 'campaign' && hasPermission('update-sms-campaign');
});

const editHref = computed(() => {
  if (!isEditableCampaign.value) {
    return;
  }

  return props.message.type === 'campaign'
    ? route('sms.campaigns.flows.edit', {
        campaign: props.message.campaign?.id,
        message: props.message.id
      })
    : route('sms.scheduled-messages.edit', { scheduledMessage: props.message.id });
});

const overridesBadgeLabel = computed(() => {
  return `${props.message.overrides?.length} ${pluralize(
    'Override',
    props.message.overrides?.length
  )}`;
});

const overrideToDelete = ref<AutomatedOutboundMessageOverrideData | null>();
const scheduledMessageToDelete = ref<UpcomingMessageData | null>();

const deleteOverrideForm = useForm({
  method: 'DELETE',
  url: () =>
    route('sms.outbound-message-override.delete', {
      message: props.message.id,
      override: overrideToDelete.value?.id
    }),
  preserveScroll: true,
  preserveState: true,
  fields: {},
  hooks: {
    success() {
      emit('upcomingMessagesUpdated');
      overrideToDelete.value = null;
    }
  }
});

const deleteScheduledMessageForm = useForm({
  method: 'DELETE',
  url: () =>
    route('sms.scheduled-messages.delete', {
      scheduledMessage: scheduledMessageToDelete.value?.id
    }),
  preserveScroll: true,
  preserveState: true,
  fields: {},
  hooks: {
    success() {
      emit('upcomingMessagesUpdated');
      scheduledMessageToDelete.value = null;
    }
  }
});

const overrideToEdit = ref<AutomatedOutboundMessageOverrideData | undefined>();
const isEditing = computed(() => !!overrideToEdit.value);

const showOverrideSlideout = ref<boolean>(false);

function editOverride(override: AutomatedOutboundMessageOverrideData) {
  overrideToEdit.value = override;
  showOverrideSlideout.value = true;
}

function createOverride() {
  overrideToEdit.value = undefined;
  showOverrideSlideout.value = true;
}

function close() {
  overrideToEdit.value = undefined;
}
</script>

<template>
  <div class="rounded-lg bg-secondary-50 shadow-sm" v-bind="$attrs">
    <header class="rounded-t-lg px-3 py-2">
      <div class="flex w-full justify-between">
        <div class="flex items-center text-xs leading-none text-slate-600">
          <CalendarIcon class="mr-2 h-3 w-3 text-secondary-900" />
          Sending on
          <DateTime
            format="datetime"
            :date="message.scheduled_send_date"
            class="ml-1 font-semibold text-secondary-900"
          />
        </div>
        <div class="flex items-center gap-2">
          <Badge
            v-if="message.overrides?.length"
            variant="outlined"
            color="primary"
            size="sm"
            :label="overridesBadgeLabel"
          />
          <Badge variant="soft" size="sm" :iconLeft="badge.icon" :label="badge.label" />
        </div>
      </div>
    </header>
    <div>
      <div class="flex gap-1 rounded-t-lg border-x border-t bg-white px-3 py-2">
        <div class="py-1">
          <CommentAltIcon class="h-3 w-3 text-slate-600" />
        </div>
        <p class="text-sm text-slate-900">{{ message.body }}</p>
      </div>
      <div v-if="message.overrides?.length" class="border-x bg-white pb-2">
        <Collapsible :title="`Overrides (${message.overrides?.length})`">
          <template #trigger>
            <div class="flex items-center px-4">
              Overrides ({{ message.overrides?.length }})
              <IconAngleRight
                class="h-3 w-3 text-slate-500 opacity-70 transition-transform duration-200 group-data-[state=open]:rotate-90"
              />
            </div>
          </template>
          <ul class="mt-1 w-full">
            <li
              v-for="override in message.overrides"
              :key="override.id"
              class="flex items-center justify-between gap-1 border-t px-3 py-2 last:pb-0"
            >
              <div class="flex-1">
                <div class="inline-flex flex-wrap gap-1" v-if="override.recipients?.length">
                  <Badge
                    v-for="recipient in override.recipients"
                    :key="recipient.id"
                    :label="recipient.name"
                    variant="outlined"
                    size="sm"
                  />
                  <span class="inline-block text-sm leading-5 text-slate-600">
                    {{
                      override.should_skip_sending
                        ? 'will not receive this message.'
                        : 'will receive the following message:'
                    }}
                  </span>
                </div>

                <div v-if="!override.should_skip_sending" class="mt-1 flex gap-1">
                  <div class="py-1">
                    <CommentAltIcon class="h-3 w-3 text-slate-600" />
                  </div>
                  <p class="break-words text-sm text-slate-900">{{ override.body }}</p>
                </div>
              </div>

              <div class="flex flex-shrink-0 items-center gap-1">
                <IconButton
                  :icon="IconEditPencil"
                  @click="editOverride(override)"
                  variant="invisible"
                  color="slate"
                  ariaLabel="Edit this override"
                />
                <IconButton
                  :icon="IconTrash"
                  variant="invisible"
                  @click="overrideToDelete = override"
                  color="danger"
                  ariaLabel="Delete this override"
                />
              </div>
            </li>
          </ul>
        </Collapsible>
      </div>
    </div>
    <footer
      class="flex flex-col justify-between rounded-b-lg border px-3 py-2 md:flex-row md:items-center"
    >
      <div class="flex flex-col space-y-1 lg:flex-row lg:items-center lg:space-y-0">
        <div class="flex items-center gap-1">
          <span class="text-xs leading-none text-slate-700">Sending to:</span>
          <template v-for="recipient in message.recipients">
            <Badge
              v-if="recipient.type === 'thread' && recipient.thread_id"
              :key="recipient.thread_id"
              class="pointer-events-none"
              size="sm"
              :label="
                message.sending_to_parents ? `Parent/Guardian of ${recipient.name}` : recipient.name
              "
            />
            <Badge
              v-else-if="recipient.student_group_id"
              :key="recipient.student_group_id"
              class="pointer-events-none"
              size="sm"
              :label="recipient.name"
            />
          </template>
          <Badge
            v-if="message.type === 'campaign'"
            size="sm"
            :label="message.campaign?.student_group?.name"
          />
        </div>
        <div
          v-if="message.type === 'campaign'"
          class="space-x-1 text-xs lg:ml-2 lg:border-l lg:pl-2"
        >
          <span>Campaign:</span>
          <Badge size="sm" :label="message.campaign?.name" />
        </div>
      </div>

      <div class="mt-2 grid w-full grid-cols-3 self-end md:mt-0 md:w-auto md:space-x-1 lg:flex">
        <Button
          v-if="message.type === 'scheduled'"
          :color="ButtonColor.danger"
          :variant="ButtonVariant.invisible"
          @click="scheduledMessageToDelete = message"
        >
          Cancel
        </Button>
        <Button
          v-if="message.type === 'campaign'"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          @click="createOverride"
        >
          Override
        </Button>
        <Button
          v-if="isEditableCampaign"
          :href="editHref"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.invisible"
        >
          Edit
        </Button>
        <Button
          v-if="message.type === 'scheduled'"
          :color="ButtonColor.slate"
          :href="route('sms.scheduled-messages.send', { scheduledMessage: message.id })"
          :hrefOptions="{
            method: 'POST',
            preserveScroll: true,
            preserveState: true,
            hooks: { success: () => emit('upcomingMessagesUpdated') }
          }"
          :variant="ButtonVariant.outlined"
        >
          Send Now
        </Button>
      </div>
    </footer>
  </div>
  <DangerDialog
    :title="`Delete Override for ${overrideToDelete?.recipients?.map((r) => r.name).join(', ')}`"
    cancelButtonLabel="Cancel"
    confirmButtonColor="danger"
    :isLoading="deleteOverrideForm.processing"
    @onCancel="overrideToDelete = null"
    @onClose="overrideToDelete = null"
    @onConfirm="deleteOverrideForm.submit"
    confirmButtonLabel="Delete"
    message="Are you sure you want to delete this override?"
    :isOpen="!!overrideToDelete"
  />
  <DangerDialog
    :title="`Delete Scheduled Message`"
    cancelButtonLabel="Cancel"
    confirmButtonColor="danger"
    :isLoading="deleteScheduledMessageForm.processing"
    @onCancel="deleteScheduledMessageForm.reset"
    @onClose="deleteScheduledMessageForm.reset"
    @onConfirm="deleteScheduledMessageForm.submit"
    confirmButtonLabel="Delete"
    message="Are you sure you want to delete this scheduled message?"
    :isOpen="!!scheduledMessageToDelete"
  />
  <!--  The key here forces the dialog to re-render each time it's opened, ensuring the form data is set properly  -->
  <AutomatedOutboundMessageOverrideSlideout
    :key="`${message.id}-${showOverrideSlideout}`"
    v-model:show="showOverrideSlideout"
    :isEditing
    :override="overrideToEdit"
    :message="message"
    @updatedOverride="emit('upcomingMessagesUpdated')"
    @createdOverride="emit('upcomingMessagesUpdated')"
    @close="close"
  />
</template>
