<script setup lang="tsx">
import Dialog from '@/components/dialog/Dialog.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import DistillerEmptyState from '@/components/distiller/DistillerEmptyState.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import { AppliedFilters, AppliedFilter } from '@/components/distiller/types';
import { useFilters } from '@/hooks/useFilters';
import pluralize from 'pluralize';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { debounce, set } from 'lodash';
import SpinnerIcon from '@/icons/line/spinner.svg';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  distiller: App.Search.Data.DistillerData;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  cancel: [void];
  close: [void];
  confirm: [void];
}>();

const computedDistiller = computed(() => {
  if (props.uploadType.id === 'student') {
    const distiller = props.distiller;
    distiller.filters = distiller.filters?.filter((filter) => filter.key !== 'school') ?? null;
    return distiller;
  }
  return props.distiller;
});

const form = useForm<{ filters: AppliedFilters; meta: { school_id: Selectable<number> | null } }>({
  method: 'POST',
  url: route('upload-sessions.existing-data.export', { uploadType: props.uploadType.id }),
  fields: {
    filters: {},
    meta: {
      school_id: null
    }
  },
  preserveState: true,
  hooks: {
    success: () => {
      emit('confirm');
    }
  },
  transform: () => ({
    filters: appliedFilters.value
  })
});

function handleCancel() {
  form.resetFields();
  emit('cancel');
}

function handleClose() {
  form.resetFields();
  emit('close');
}

const {
  filteredRecordsCount,
  formattedFilteredRecordsCount,
  getRecordCount,
  isLoadingRecordCount
} = useFilters();

const appliedFilters = computed(() => {
  if (props.uploadType.id === 'student' && form.fields.meta.school_id?.value !== undefined) {
    return set({ ...form.fields.filters }, 'school.id', {
      operator: 'equals' as App.Search.Enums.FilterOperator,
      value: form.fields.meta.school_id?.value
    } as unknown as AppliedFilter);
  }
  return form.fields.filters;
});

const refreshRecordCount = debounce(() => {
  getRecordCount(props.distiller, appliedFilters.value);
}, 350);

watch(() => form.fields.filters, refreshRecordCount, { deep: true });
watch(() => form.fields.meta.school_id, refreshRecordCount, { deep: true });

onMounted(() => {
  refreshRecordCount();
});

const description = computed(() => {
  if (props.uploadType.id === 'student') {
    return 'All students from the selected school will be used for this new Upload session';
  }
  return `All ${pluralize(props.uploadType.record_name)} will be used for this new Upload session`;
});

const addingNewFilter = ref(false);
</script>

<template>
  <Dialog
    confirmButtonLabel="Export data"
    contentClass="max-w-3xl"
    :isOpen="isOpen"
    :isLoading="form.processing"
    :confirmButtonDisabled="filteredRecordsCount?.count === 0"
    :title="`Export ${pluralize(uploadType.name)} as an Excel file`"
    @onCancel="handleCancel"
    @onClose="handleClose"
    @onConfirm="form.submit"
  >
    <template #body>
      <template v-if="uploadType.id === 'student'">
        <div class="space-y-2">
          <p class="text-xs font-bold uppercase leading-3 text-zinc-500">Pull data from</p>

          <FormSchoolInput
            name="meta.school_id"
            label="Schools"
            :error="form.fields.meta.school_id ? undefined : form.errors.filters?.school?.id?.value"
            :modelValue="form.fields.meta.school_id"
            @update:modelValue="(value) => (form.fields.meta.school_id = value)"
          />
        </div>

        <hr class="my-8 border-zinc-200" />
      </template>

      <div class="space-y-2">
        <p class="text-xs font-bold uppercase leading-3 text-zinc-500">Filters</p>
        <FilterProvider v-model="form.fields.filters">
          <DistillerEmptyState
            :description="description"
            :addingNewFilter="addingNewFilter"
            @update:addingNewFilter="addingNewFilter = $event"
          >
            <Distiller
              :distiller="computedDistiller"
              title="Filters"
              v-model:addingNewFilter="addingNewFilter"
            />
          </DistillerEmptyState>
        </FilterProvider>
        <div
          v-if="
            uploadType.id !== 'student' ||
            (uploadType.id === 'student' && form.fields.meta.school_id)
          "
          class="flex items-center gap-2 pt-2 text-sm"
          :class="filteredRecordsCount?.count === 0 ? 'text-red-700' : 'text-zinc-700'"
        >
          The selected filters will create an Excel file with
          <SpinnerIcon
            v-if="isLoadingRecordCount"
            class="h-4 w-4 animate-spin text-secondary-900"
          />
          <template v-else>
            {{ formattedFilteredRecordsCount }}
            {{ pluralize(distiller.name?.label ?? 'Record', filteredRecordsCount?.count) }}
          </template>
          .
        </div>
      </div>
    </template>
  </Dialog>
</template>
