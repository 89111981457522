<script setup lang="ts">
import { CollapsibleContent, CollapsibleRoot, CollapsibleTrigger } from 'radix-vue';

import { ButtonColor } from '@/hooks/useButtonClasses';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import BadgeTruncator from '@/components/badge/BadgeTruncator.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';
import StudentInteractionDefinitionList from '@/components/students/StudentInteractionDefinitionList.vue';
import StudentInteractionDefinitionListItem from '@/components/students/StudentInteractionDefinitionListItem.vue';

import DocumentLayoutLeft from '@/icons/line/document-layout-left.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import IconAngleRight from '@/icons/line/angle-right.svg';
import TrashAlt from '@/icons/line/trash-alt.svg';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import IconButton from '@/components/button/IconButton.vue';

const props = defineProps<{
  interaction: App.Students.Data.InteractionData;
  student: App.Students.Data.StudentData;
}>();

const { isDialogOpen, toggleDialog } = useDialogHelpers();

const deleteForm = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('students.interactions.delete', {
    student: props.student.id,
    interaction: props.interaction.id
  }),
  fields: {},
  hooks: {
    success() {
      close();
      router.reload({ only: ['interactions'] });
    }
  }
});

function handleInteractionDeleteClick() {
  toggleDialog();
}

async function handleInteractionDelete() {
  await deleteForm.submit();
  toggleDialog();
}
</script>

<template>
  <CollapsibleRoot
    class="overflow-hidden rounded-2xl border border-slate-200"
    type="single"
    :collapsible="true"
  >
    <CollapsibleTrigger
      class="group relative flex w-full cursor-pointer items-center gap-3 px-5 py-4 text-left outline-none"
    >
      <IconAngleRight
        class="h-5 w-5 shrink-0 text-slate-700 transition-transform duration-200 group-data-[state=open]:rotate-90"
      />
      <div class="min-w-16" v-if="interaction.date">
        <p class="whitespace-nowrap text-base font-semibold leading-6 text-slate-900">
          {{ formatDate(interaction.date, { format: 'MMM d' }) }}
        </p>
        <p class="mt-1 text-sm font-semibold leading-4 text-slate-500">
          {{ formatDate(interaction.date, { format: 'yyyy' }) }}
        </p>
      </div>
      <VerticalDivider
        class="hidden opacity-0 transition-opacity group-data-[state=closed]:opacity-100 sm:block"
      />
      <div
        class="flex min-w-0 grow flex-col gap-1 pl-2 opacity-0 transition-opacity group-data-[state=closed]:opacity-100 sm:px-2"
      >
        <p class="hidden truncate text-sm leading-5 sm:block">{{ interaction.notes }}</p>
        <BadgeTruncator
          v-if="interaction.topics"
          :items="interaction.topics"
          class="justify-end sm:justify-start"
        />
      </div>
      <VerticalDivider
        class="hidden shrink-0 opacity-0 transition-opacity group-data-[state=closed]:opacity-100 sm:block"
      />
      <div
        class="absolute right-5 flex shrink-0 gap-x-2 transition-opacity group-data-[state=closed]:opacity-0 group-data-[state=open]:opacity-100 sm:relative sm:relative sm:right-0 sm:group-data-[state=closed]:opacity-100"
      >
        <IconButton
          :color="ButtonColor.slate"
          :href="
            route('students.interactions.edit', {
              student: student.id,
              interaction: interaction.id
            })
          "
          :icon="EditAlt"
          ariaLabel="Edit interaction"
          class="group-data-[state=closed]:hidden sm:group-data-[state=closed]:inline-flex"
          size="sm"
          variant="invisible"
          @click.stop
        />
        <IconButton
          :color="ButtonColor.danger"
          :icon="TrashAlt"
          ariaLabel="Delete interaction"
          class="group-data-[state=closed]:hidden sm:group-data-[state=closed]:inline-flex"
          size="sm"
          variant="invisible"
          @click.stop="handleInteractionDeleteClick"
        />
      </div>
    </CollapsibleTrigger>
    <CollapsibleContent
      class="collapsible-content overflow-hidden border-t border-slate-200 bg-slate-50"
    >
      <div class="flex flex-col divide-y divide-slate-200 lg:flex-row lg:divide-x lg:divide-y-0">
        <div class="flex flex-col gap-2 px-5 py-4 lg:w-1/2">
          <div class="flex items-center gap-2">
            <DocumentLayoutLeft class="h-4.5 w-4.5 text-slate-700 opacity-70" />
            <h3 class="text-sm font-semibold leading-6 text-slate-700">Note</h3>
          </div>
          <p class="text-sm leading-5 text-slate-700">{{ interaction.notes }}</p>
        </div>
        <div class="flex flex-col gap-2 px-5 py-4 lg:w-1/2">
          <div class="flex items-center gap-2">
            <DocumentLayoutLeft class="h-4.5 w-4.5 text-slate-700 opacity-70" />
            <h3 class="text-sm font-semibold leading-6 text-slate-700">Tags</h3>
          </div>
          <StudentInteractionDefinitionList>
            <StudentInteractionDefinitionListItem title="Topic">
              <Badge
                v-for="topic in interaction.topics"
                :key="topic.value"
                :label="topic.label"
                size="md"
                variant="soft"
              />
            </StudentInteractionDefinitionListItem>
            <StudentInteractionDefinitionListItem title="Type">
              <Badge
                v-for="type in interaction.types"
                :key="type.value"
                :label="type.label"
                size="md"
                variant="soft"
              />
            </StudentInteractionDefinitionListItem>
            <StudentInteractionDefinitionListItem title="Duration">
              <Badge :label="interaction.meeting_length.label" size="md" variant="soft" />
            </StudentInteractionDefinitionListItem>
            <StudentInteractionDefinitionListItem title="Medium">
              <Badge :label="interaction.medium.label" size="md" variant="soft" />
            </StudentInteractionDefinitionListItem>
          </StudentInteractionDefinitionList>
        </div>
      </div>
    </CollapsibleContent>
  </CollapsibleRoot>
  <DangerDialog
    title="Delete Interaction"
    message="Are you sure you want to delete this interaction?"
    confirmButtonLabel="Delete interaction"
    :isOpen="isDialogOpen"
    :isLoading="deleteForm.processing"
    @onConfirm="handleInteractionDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>

<style scoped>
@keyframes open-collapsible-content {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
}

@keyframes close-collapsible-content {
  from {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.collapsible-content[data-state='open'] {
  animation: open-collapsible-content 300ms ease-in;
}

.collapsible-content[data-state='closed'] {
  animation: close-collapsible-content 300ms ease-out;
}
</style>
