<script setup lang="ts">
import UpcomingMessageCard from '@/domains/sms/components/UpcomingMessageCard.vue';
import ThreadsEmptyState from '@/components/emptyState/ThreadsEmptyState.vue';
import { indexThreadScheduledMessages } from '@/api/ThreadApi';
import { useMessageList } from '@/hooks/useMessageList';
import UpcomingMessageData = App.Sms.Data.UpcomingMessageData;

const props = defineProps<{
  threadId: number;
  messageContainer: HTMLElement | null;
}>();

const { messages, groupedMessages, refreshAllPages, isLoading } =
  useMessageList<UpcomingMessageData>({
    threadId: props.threadId,
    api: indexThreadScheduledMessages,
    messageContainer: props.messageContainer,
    infiniteScrollDirection: 'bottom',
    sortKey: ['scheduled_send_date', 'id']
  });
</script>

<template>
  <div class="flex-1 overflow-y-auto px-6 py-4">
    <ThreadsEmptyState
      v-if="messages.length === 0"
      inline
      :title="isLoading ? 'Loading Messages' : 'No scheduled messages'"
      :description="isLoading ? '' : 'There are no upcoming messages to display.'"
      :actionLabel="isLoading ? 'Loading...' : ''"
      :isLoading="isLoading"
    />

    <!-- Ensure scheduled message are pushed to the bottom -->
    <div aria-hidden="true" class="flex-1" />

    <template v-for="(messages, day) in groupedMessages" :key="day">
      <h3 class="mb-2 mt-4 text-center text-xs font-bold uppercase text-slate-600 first:mt-0">
        {{ day }}
      </h3>

      <UpcomingMessageCard
        v-for="message in messages"
        :message="message"
        :key="message.id"
        class="mt-2 last:mb-6"
        @upcomingMessagesUpdated="refreshAllPages"
      />
    </template>
  </div>
</template>
