<script setup lang="ts">
import { formatDate } from '@/utils/date';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { formatNumber } from '@/utils/numbers';

import Button from '@/components/button/Button.vue';
import FormError from '@/components/formError/FormError.vue';

import Save from '@/icons/line/save.svg';
import Times from '@/icons/line/times.svg';

const isEditTextareaFocused = ref(false);

const props = defineProps<{
  studentId: number;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onSuccess: [void];
}>();

const { user } = useAuth();

const editForm = useForm({
  method: 'POST',
  url: route('notes.store'),
  fields: {
    text: '',
    student_id: props.studentId
  },
  hooks: {
    success() {
      router.reload({ only: ['notes'] });
      emit('onSuccess');
    }
  }
});

function handleCancelClick() {
  emit('onCancel');
}

function handleToggleTextareaFocusState() {
  editForm.clearErrors();
  isEditTextareaFocused.value = !isEditTextareaFocused.value;
}

async function handleNoteEdit() {
  await editForm.submit();
}
</script>

<template>
  <div class="rounded-lg border-2 border-dashed border-slate-200 bg-slate-50">
    <div class="flex justify-between gap-2 px-4 pb-4 pt-5">
      <div>
        <p class="text-xl font-semibold leading-6 text-slate-900">
          {{ formatDate(new Date()) }}
        </p>
        <p class="mt-1 text-sm font-semibold leading-4 text-slate-500">
          {{ user?.name }}
        </p>
      </div>
      <div>
        <Button
          :color="ButtonColor.slate"
          :iconRight="Times"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.invisible"
          @click="handleCancelClick"
          >Cancel</Button
        >
      </div>
    </div>
    <form
      class="rounded-b-lg border-t border-slate-200"
      :class="{
        'ring-3 ring-slate-300/30': isEditTextareaFocused && !editForm.errors.text,
        'ring-3 ring-red-300/30': isEditTextareaFocused && editForm.errors.text
      }"
    >
      <textarea
        class="block w-full border-0 px-4 py-3 text-[16px] leading-5 text-slate-800 focus:shadow-none focus:ring-0 md:text-sm"
        :class="{
          'bg-white': !editForm.errors.text,
          'bg-red-50': editForm.errors.text
        }"
        rows="8"
        @blur="handleToggleTextareaFocusState"
        @focus="handleToggleTextareaFocusState"
        v-model="editForm.fields.text"
      />
      <div class="flex items-center border-t border-slate-200 px-2 py-1.5">
        <div class="grow">
          <FormError v-if="editForm.errors.text">{{ editForm.errors.text }}</FormError>
          <p
            v-else
            class="text-xs"
            :class="{
              'text-red-700': editForm.fields.text?.length > 65535,
              'text-slate-500': editForm.fields.text?.length <= 65535
            }"
          >
            {{ formatNumber(editForm.fields.text?.length || 0) }} / 65,535
          </p>
        </div>
        <Button
          class="shrink-0"
          :color="ButtonColor.primary"
          :iconRight="Save"
          :isDisabled="editForm.processing"
          :isLoading="editForm.processing"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
          @click="handleNoteEdit"
          >Create note</Button
        >
      </div>
    </form>
  </div>
</template>
