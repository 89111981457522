<script setup lang="ts">
import { Position } from '@vue-flow/core';
import { NodeToolbar } from '@vue-flow/node-toolbar';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import PlusIcon from '@/icons/line/plus.svg';

const target = ref(null);

onClickOutside(target, () => {
  isOpen.value = false;
});

const isOpen = ref(false);

function closePopover() {
  isOpen.value = false;
}
</script>

<template>
  <button
    aria-label="Add"
    class="absolute bottom-0 left-1/2 -translate-x-3 translate-y-3 rounded-full bg-slate-200 p-1 ring-2 ring-slate-50 hover:bg-slate-300 hover:ring-slate-200"
    @click.stop="isOpen = true"
  >
    <PlusIcon class="h-4 w-4 text-sky-800" />
  </button>
  <NodeToolbar :isVisible="isOpen" :position="Position.Bottom">
    <div ref="target" class="w-52 space-y-2 rounded-lg bg-slate-800/[0.98] px-2 py-3">
      <p class="text-center text-xs font-bold uppercase tracking-widest text-white">Add</p>

      <div class="space-y-2">
        <slot v-bind="{ isOpen, closePopover }" />
      </div>
    </div>
  </NodeToolbar>
</template>
