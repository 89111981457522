<script setup lang="ts">
import type { TableSelectionState } from '@/hooks/useHybridlyTable';
import type { Selectable } from '@/components/selectBox/selectBox';

import pluralize from 'pluralize';

import { buildSelectableStudent } from '@/utils/buildSelectable';
import { formatNumber } from '@/utils/numbers';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';

const props = defineProps<{
  isOpen: boolean;
  selectedStudents?: App.Students.Data.StudentData[];
  selectedStudentsCount?: number;
  tableSelectionState?: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const form = useForm({
  method: 'POST',
  url: route('students.bulk-store.notes'),
  fields: {
    ids:
      props.tableSelectionState?.selectedRowIds ||
      props.selectedStudents?.map((student) => buildSelectableStudent(student)) ||
      ([] as Selectable<number>[]),
    filters: queryParameters.filters || null,
    all: props.tableSelectionState?.areAllRecordsSelected,
    text: ''
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState?.selectedRowIds || fields.ids.map((student) => student.value),
    filters: queryParameters.filters || null,
    all: props.tableSelectionState?.areAllRecordsSelected ?? false
  }),
  hooks: {
    success() {
      props.tableSelectionState?.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Add note"
    description="Add the same note for all selected students"
    title="Add Note"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset
        v-if="!tableSelectionState?.selectedRowIds.length && !selectedStudents?.length"
        title="Selected students"
      >
        <FormMultiStudentInput
          :error="form.errors.ids as unknown as string"
          :modelValue="form.fields.ids as Selectable<number>[]"
          label="Student"
          placeholder="Select student"
          @update:modelValue="(value) => (form.fields.ids = value)"
        />
      </Fieldset>
      <Fieldset title="Note details">
        <div class="flex flex-col gap-y-2">
          <FormTextareaInput
            :error="form.errors.text"
            :modelValue="form.fields.text"
            label="Note"
            name="text"
            @update:modelValue="(value) => (form.fields.text = String(value ?? ''))"
          />
          <p
            class="text-right text-xs"
            :class="{
              'text-red-700': form.fields.text?.length > 65535,
              'text-slate-500': form.fields.text?.length <= 65535
            }"
          >
            {{ formatNumber(form.fields.text?.length || 0) }} / 65,535
          </p>
        </div>
        <Alert v-if="selectedStudentsCount" title="Selected Students" variant="soft">
          You are creating this note for
          <span class="font-bold">{{ selectedStudentsCount }}</span> selected
          {{ pluralize('student', selectedStudentsCount) }}.
        </Alert>
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
