<script setup lang="ts">
import type { CreateFormValues } from './useUploadSessionFormWizard';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import FormComboboxInput from '@/components/formComboboxInput/FormComboboxInput.vue';
import FormRadioInput from '@/components/formRadioInput/FormRadioInput.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import IconButton from '@/components/button/IconButton.vue';

import ExclamationCircle from '@/icons/line/exclamation-circle.svg';

defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  form: ReturnType<typeof useForm<CreateFormValues>>;
}>();
const emit = defineEmits<{ cancel: [void]; submit: [void] }>();

const booleanOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

const gradeOptions = [
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 }
];

const [isOpen, toggleIsOpen] = useToggle();
</script>

<template>
  <form @submit.prevent="emit('submit')" class="flex flex-col gap-y-3">
    <section class="flex flex-col gap-y-5 px-5 py-6">
      <p class="text-xs font-bold uppercase leading-3 text-zinc-500">Session Settings</p>

      <FormTextInput
        name="name"
        label="Session name"
        :error="form.errors.name"
        :modelValue="form.fields.name"
        @update:modelValue="(value) => (form.fields.name = value)"
      />

      <template v-if="uploadType.id === 'student'">
        <FormSchoolInput
          name="meta.school_id"
          label="Which school is this upload session for?"
          :error="form.errors.meta?.school_id"
          :modelValue="form.fields.meta.school_id"
          @update:modelValue="(value) => (form.fields.meta.school_id = value)"
        />
        <FormRadioInput
          name="meta.disenroll_missing_students"
          :radioInputs="booleanOptions"
          :error="form.errors.meta?.disenroll_missing_students"
          label="Is this a full roster upload?"
          ariaLabel="Is this a full roster upload?"
          direction="horizontal"
          :value="form.fields.meta.disenroll_missing_students"
          @update:modelValue="(value) => (form.fields.meta.disenroll_missing_students = value)"
        >
          <template #info>
            <IconButton
              :icon="ExclamationCircle"
              ariaLabel="Full roster upload"
              size="sm"
              @click="toggleIsOpen(true)"
              variant="invisible"
            />
            <Dialog
              :icon="ExclamationCircle"
              :isOpen="isOpen"
              title="Full roster upload"
              @onClose="toggleIsOpen(false)"
              @onConfirm="toggleIsOpen(false)"
            >
              <div class="text-sm font-normal leading-5 text-zinc-700">
                <p>
                  If you check "Yes" to the Full Roster Upload field, the spreadsheet upload will
                  represent the entire student population for a school at a specific grade-level.
                </p>
                <br />
                <ul class="list-outside list-disc pl-4">
                  <li>
                    Students currently in GRACE for the specific school and grade-level but missing
                    from the spreadsheet should be disenrolled
                  </li>
                </ul>
              </div>
              <template #footer>
                <Button
                  :color="ButtonColor.primary"
                  :size="ButtonSize.sm"
                  :variant="ButtonVariant.solid"
                  type="submit"
                  >Close</Button
                >
              </template>
            </Dialog>
          </template>
        </FormRadioInput>

        <template v-if="form.fields.meta.disenroll_missing_students === booleanOptions[0].value">
          <FormComboboxInput
            name="meta.grade"
            placeholder="Select grade"
            :options="gradeOptions"
            :error="form.errors.meta?.grade"
            label="Grade"
            :modelValue="form.fields.meta.grade"
            @update:modelValue="(value) => (form.fields.meta.grade = value)"
          />
        </template>
      </template>
    </section>

    <section class="flex flex-row justify-end gap-x-2 px-5 py-2.5">
      <Button
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        type="button"
        @click="$emit('cancel')"
        >Previous</Button
      >
      <Button
        :color="ButtonColor.primary"
        :isLoading="form.processing"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        type="submit"
        >Create upload session</Button
      >
    </section>
  </form>
</template>
