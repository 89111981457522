import { formatDate } from './date';

export function buildSelectableRegion(
  region: App.Configuration.Data.RegionData | null | undefined
) {
  if (region) {
    return {
      value: region.id,
      label: region.display_name
    };
  }
}

export function buildSelectableProgram(
  program: App.Configuration.Data.ProgramData | null | undefined
) {
  if (program) {
    return {
      value: program.id,
      label: program.display_name
    };
  }
}

export function buildSelectableSchool(
  school: App.Configuration.Data.SchoolData | null | undefined
) {
  if (school) {
    return {
      value: school.id,
      label: school.name,
      description: school.address
    };
  }
}

export function buildSelectableInstitute(
  institute: App.Configuration.Data.InstituteData | null | undefined
) {
  if (institute) {
    return {
      value: institute.id,
      label: institute.name,
      description: [institute.address, institute.city, institute.state].filter(Boolean).join(', ')
    };
  }
}

export function buildSelectableSurvey(survey: App.Surveys.Data.SurveyData | null | undefined) {
  if (survey) {
    return {
      value: survey.id,
      label: survey.name,
      description: survey.school
    };
  }
}

export function buildSelectableScholarship(
  scholarship: App.Configuration.Data.ScholarshipData | null | undefined
) {
  if (scholarship) {
    return {
      value: scholarship.id,
      label: scholarship.name
    };
  }
}

export function buildSelectableStudent(student: App.Students.Data.StudentData | null | undefined) {
  if (student) {
    return {
      value: student.id,
      label: student.full_name,
      description: `DOB: ${student.dob ? formatDate(student.dob) : '--'}, ${student.grade.label}`
    };
  }
}

export function buildSelectableStudentParent(
  parent: App.Students.Data.StudentParentData | null | undefined
) {
  if (parent) {
    return {
      value: parent.id,
      label: parent.name
    };
  }
}

export function buildSelectableUser(user: App.Auth.Data.UserData | null | undefined) {
  if (user) {
    return {
      value: user.id,
      label: user.name,
      description: user.email
    };
  }
}

export function buildSelectableRole(role: App.Auth.Data.RoleData | null | undefined) {
  if (role) {
    return {
      value: role.name,
      label: role.display_name
    };
  }
}

export function buildSelectableGroup(group: App.Students.Data.StudentGroupData | null | undefined) {
  if (group) {
    return {
      value: group.id,
      label: group.name
    };
  }
}

export function buildSelectableCustomTest(
  customTest: App.Configuration.Data.CustomTestData | null | undefined
) {
  if (customTest) {
    return {
      value: customTest.name,
      label: customTest.display_name
    };
  }
}

export function buildSelectableSurveyBlock(
  block: App.Surveys.Data.SurveyBlockData | null | undefined
) {
  if (block) {
    return {
      value: block.id,
      label: `${block.order + 1}. ${block.block_text}`
    };
  }
}

export function buildSelectableSurveyChoice(
  choice: App.Surveys.Data.SurveyChoiceData | null | undefined
) {
  if (choice) {
    return {
      value: choice.id,
      label: choice.choice_text
    };
  }
}

export function buildSelectableEnum(enumeral: App.Base.Data.EnumData | null | undefined) {
  if (enumeral) {
    return {
      value: enumeral.value,
      label: enumeral.label,
      ...(enumeral?.description && { description: enumeral.description }),
      ...(enumeral?.header && { header: enumeral.header })
    };
  }
}

export function buildSelectableDataImportType(
  dataImportType: App.Bulkinator.Data.DataImportTypeData | null | undefined
) {
  if (dataImportType) {
    return {
      value: dataImportType.id,
      label: dataImportType.name
    };
  }
}

export function buildSelectableCaseload(caseload: App.Sms.Data.CaseloadData | null | undefined) {
  if (caseload) {
    return {
      value: caseload.id,
      label: caseload.name
    };
  }
}
