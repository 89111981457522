<script setup lang="ts" generic="T extends SelectableValue">
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxPortal,
  ComboboxTrigger,
  ComboboxViewport,
  ComboboxRoot
} from 'radix-vue';

import {
  Selectable,
  SelectableValue,
  SingleSelectBoxProps,
  useSelectBox
} from '@/components/selectBox/selectBox';
import NoResults from '@/components/selectBox/combobox/NoResults.vue';
import SelectBoxViewport from '@/components/selectBox/SelectBoxViewport.vue';
import ComboboxSearchInput from './ComboboxSearchInput.vue';
import ComboboxOption from './ComboboxOption.vue';
import { useZindex } from '@/hooks/useZindex';
import Loading from '@/components/loading/Loading.vue';

import AngleDownIcon from '@/icons/line/angle-down.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import LockIcon from '@/icons/line/lock.svg';
import TimesIcon from '@/icons/line/times.svg';
import IconButton from '@/components/button/IconButton.vue';

const props = withDefaults(
  defineProps<
    SingleSelectBoxProps<T> & {
      clearable?: boolean;
    }
  >(),
  {
    clearable: false
  }
);

defineSlots<{
  options?(props: { focusOnInput: () => void }): void;
  trigger?(props: { modelValue?: Selectable<T> | null; placeholder?: string }): void;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: Selectable<T> | null];
  open: [];
}>();

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const {
  filteredOptions,
  focusOnInput,
  focusOnTrigger,
  handleSearchQuery,
  hasNoResultsForQuery,
  searchQuery,
  triggerClasses,
  triggerElement,
  isTouchPointer,
  triggerEvents
} = useSelectBox<T>(props);

const rightStatusIcon = computed(() => {
  if (props.isLoading) {
    return SpinnerIcon;
  }
  if (props.isSuccessful) {
    return CheckCircleIcon;
  }
});

function handleValueChange(value: Selectable<T> | null) {
  emit('update:modelValue', value);

  handleSearchQuery('');
  focusOnTrigger();
}
</script>

<template>
  <ComboboxRoot
    :modelValue="modelValue as Selectable<T>"
    @update:modelValue="handleValueChange"
    @update:open="$emit('open')"
    :filterFunction="(opts) => opts"
    :disabled="isDisabled || isReadonly"
    v-bind="$attrs"
    @keydown.enter.prevent
  >
    <ComboboxAnchor class="h-full">
      <ComboboxTrigger
        ref="triggerElement"
        :class="[triggerClasses, inputClasses]"
        class="group flex items-center justify-between gap-1"
        tabindex="0"
        v-on="triggerEvents"
      >
        <div
          class="flex flex-col truncate pl-1.5 text-left"
          :class="{ 'text-slate-400': !modelValue?.value }"
          :title="modelValue?.label ?? placeholder"
        >
          <slot name="trigger" :placeholder="placeholder" :modelValue="modelValue">
            <p class="truncate leading-4">{{ modelValue?.label ?? placeholder }}</p>
            <p v-if="modelValue?.description" class="truncate text-xs leading-3 text-slate-500">
              {{ modelValue.description }}
            </p>
          </slot>
        </div>

        <div class="flex items-center">
          <IconButton
            v-if="clearable && modelValue && !isDisabled"
            asChild
            :icon="TimesIcon"
            class="pointer-events-auto flex items-center justify-center"
            ariaLabel="Clear search"
            @click.stop="handleValueChange(null)"
            variant="invisible"
            size="xs"
          />
          <component
            v-if="rightStatusIcon"
            :is="rightStatusIcon"
            class="h-4.5 w-4.5 shrink-0"
            :class="{
              'text-green-500': !isLoading && isSuccessful,
              'animate-spin text-slate-500': isLoading
            }"
          />
          <LockIcon
            v-else-if="isReadonly === true"
            class="h-4.5 w-4.5 shrink-0 text-slate-500 text-slate-500/25"
          />
          <AngleDownIcon
            v-else
            class="h-4.5 w-4.5 shrink-0 text-slate-500 group-disabled:text-slate-500/25"
          />
        </div>
      </ComboboxTrigger>
    </ComboboxAnchor>

    <ComboboxPortal>
      <ComboboxContent
        align="start"
        :sideOffset="4"
        position="popper"
        @escapeKeyDown="focusOnTrigger"
        @interactOutside="focusOnTrigger"
        class="rounded-lg border border-slate-200 bg-white drop-shadow-md"
        :class="[contentWidth || 'w-[--radix-combobox-trigger-width]']"
        :style="{ zIndex }"
      >
        <ComboboxSearchInput
          ref="searchInputElement"
          :searchQuery="searchQuery"
          :isLoading="isLoading"
          @searchQuery="handleSearchQuery"
          :isTouchPointer="isTouchPointer"
        />

        <slot name="optionsHeader" />

        <SelectBoxViewport :as="ComboboxViewport">
          <slot name="options" :focusOnInput="focusOnInput">
            <ComboboxOption
              v-for="option in filteredOptions"
              :class="{
                'ml-4': option.header
              }"
              :key="`${option.value}-${option.label}`"
              :checked="option.value === modelValue?.value"
              :option="option"
              :modelValue="modelValue"
              :disabled="option?.disabled ?? false"
              @click="focusOnInput"
            />
          </slot>
          <slot name="noResults" v-if="filteredOptions.length === 0 && isLoading">
            <Loading iconColor="primary" title="Loading results..." />
          </slot>
          <slot name="noResults" v-if="hasNoResultsForQuery" v-bind="{ searchQuery }">
            <NoResults />
          </slot>
        </SelectBoxViewport>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>
