<script setup lang="ts">
import ScheduledMessageData = App.Sms.Data.ScheduledMessageData;
import MessagingPageLayout from '@/components/pageLayout/MessagingPageLayout.vue';
import ComposeMessageInput from '@/components/sms/ComposeMessageInput.vue';
import RecipientListDialog from '@/domains/sms/components/RecipientListDialog.vue';
import RecipientErrors from '@/domains/sms/components/RecipientErrors.vue';
import DataHookData = App.Sms.Data.DataHookData;
import ScheduledMessageRecipientData = App.Sms.Data.ScheduledMessageRecipientData;
import { WithLoadedRelations } from '@/@types/global';

const props = defineProps<{
  hasSmsEnabled: boolean;
  message: WithLoadedRelations<ScheduledMessageData, 'recipients'>;
  smsRecipientTypeOptions: App.Base.Data.EnumData[];
  dataHooks: DataHookData[];
}>();

const recipientErrors = ref<string[] | null>(null);

const form = useForm<{
  body: string;
  recipient_type: App.Base.Data.EnumData;
  recipients: App.Sms.Data.SmsRecipientData[];
  scheduled_send_date: string;
  attachments: App.Sms.Data.SmsAttachmentData[];
}>({
  updateInitials: true,
  method: 'PUT',
  url: route('sms.scheduled-messages.edit', { scheduledMessage: props.message.id }),
  fields: {
    recipients: props.message.recipients?.map(
      ({ recipient }: ScheduledMessageRecipientData) => recipient
    ),
    attachments: props.message.attachments || [],
    scheduled_send_date: props.message.scheduled_send_date,
    recipient_type: props.smsRecipientTypeOptions[0],
    body: props.message.body
  },
  transform: (data) => ({
    ...data,
    recipient_type: data.recipient_type.value,
    attachment_ids: data.attachments.map(({ id }) => id)
  })
});

function handleRecipientsUpdate(recipients: App.Sms.Data.SmsRecipientData[]) {
  form.fields.recipients = recipients;
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <MessagingPageLayout title="Edit Scheduled Message" :sidebar="false">
    <template #thread>
      <div class="flex h-full w-full flex-col md:flex-row">
        <div
          class="divide-y-slate-200 flex h-full flex-1 flex-col divide-y border-r border-slate-200"
        >
          <div v-if="hasSmsEnabled" class="bg-dot-pattern flex flex-1 flex-col justify-end">
            <ComposeMessageInput
              v-model="form.fields.body"
              class="border-t bg-white px-2 pb-3"
              variant="scheduled"
              :error="form.errors.body"
              :scheduledSendDateError="form.errors.scheduled_send_date"
              :hasError="recipientErrors !== null"
              :isLoading="form.processing"
              v-model:attachments="form.fields.attachments"
              v-model:scheduledSendDate="form.fields.scheduled_send_date"
              @send="form.submit"
            />
          </div>
        </div>

        <div class="hidden w-full max-w-sm flex-col md:flex">
          <RecipientListDialog
            :readonly="true"
            :smsRecipientTypeOptions
            :recipients="form.fields.recipients"
            :errors="form.errors"
            @update:recipients="handleRecipientsUpdate"
            v-model:recipientType="form.fields.recipient_type"
          >
            <template #append>
              <p class="text-xs font-medium text-slate-600">
                Scheduled message recipients cannot be edited after the message has been scheduled.
                If you need to make changes, delete this one, and create a new message.
              </p>
            </template>
          </RecipientListDialog>
        </div>

        <RecipientErrors :errors="form.errors" />
      </div>
    </template>
  </MessagingPageLayout>
</template>
