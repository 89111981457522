<script setup lang="tsx">
const iconImports = import.meta.glob(`../../../icons/*.svg`, { eager: true });

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

const props = defineProps<{
  reportType: App.Reports.Data.CustomReportData;
  url: string;
}>();

const iconComponent = computed(() => iconImports[`../../../icons/${props.reportType.icon}.svg`]);
</script>

<template>
  <div class="flex flex-col items-start space-y-4 rounded-lg border border-slate-200 p-4 pt-5">
    <div class="grow space-y-2">
      <div
        class="flex h-12 w-12 items-center justify-center rounded-full border border-slate-200 bg-secondary-50"
      >
        <component :is="iconComponent" class="h-4 w-4 text-secondary-800" />
      </div>
      <h4 class="text-base font-semibold text-slate-900">{{ reportType.name }}</h4>
      <p class="text-sm text-slate-600">{{ reportType.description }}</p>
    </div>
    <Button :href="url" :variant="ButtonVariant.soft" :color="ButtonColor.primary" class="mt-auto"
      >Select and continue</Button
    >
  </div>
</template>
